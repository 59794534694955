import React, { useState, useEffect } from "react";
import { Form, Input, Select, InputNumber, message, Tooltip } from "antd";
import { useSelector } from "../../../utilities/hooks";
import {
  formatMoney,
  excludedCurrencies,
  addMoneyCountries,
} from "../../../utilities/helperFunctions";
import Search from "../../../library/Search";
import * as ctzc from "iso-country-currency";
// import bank from "../../../assets/images/bank_payment.svg";
// import momo from "../../../assets/images/momo_payment.svg";
import makeAPICall from "../../../utilities/apiUtils";
import notifyWhite from "../../../assets/images/notify-white.svg";
import notify from "../../../assets/images/notify-bank.svg";

export default function Second({ next, currentWalletDefault, type }) {
  const { wallets } = useSelector((state) => state.walletsReducer);
  // const { user } = useSelector((state) => state.profileReducer);
  const [defaultWallet, setDefauleWallet] = useState(currentWalletDefault);
  // console.log(defaultWallet, "test");
  const [inputValue, setInputValue] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [showTabs, setShowTabs] = useState(false);
  const [showWallets, setShowWallets] = useState(true);
  const [showPayMent, setShowPayMent] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loader, setLoader] = useState(false);
  const [quotationDetails, setQuotationDetails] = useState();

  let filterTimeout;
  const limit = 700;

  const { Option } = Select;

  function showOthers() {
    setShowWallets(false);
    setShowTabs(true);
  }

  const fiatWallets = wallets.filter((wallet) => {
    return wallet.isCrypto === false && wallet.enabled === true;
  });

  const selectedWallet = (item) => {
    // console.log(item, 'test')
    setDefauleWallet(item);
    setShowTabs(false);
    setShowWallets(true);
  };

  // function onChangeBank(e) {
  //   setPaymentMethod(e.target.value);
  //   if (e.target.value === "bank" || e.target.value === "momo") {
  //     setShowPayMent(true);
  //   }
  // }

  useEffect(() => {
    if (type === "bank" || type === "momo") {
      setPaymentMethod(type);
      setShowPayMent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const updateQueryOne = (value) => {
    // A search query api call.
    clearTimeout(filterTimeout);
    if (!value) setInputValue("");

    filterTimeout = setTimeout(() => {
      // console.log("=====> input value", value);
      setInputValue(value);
      setLoader(true);
      const data = {
        currency: defaultWallet.currency,
        amount: value,
        method: paymentMethod,
      };
      return makeAPICall({
        path: "transactions/collections/quotation",
        payload: data,
        method: "POST",
      })
        .then((res) => {
          // console.log(res.data);
          const { data } = res;
          setLoader(false);
          setShowDetails(true);
          setQuotationDetails(data);
        })
        .catch((err) => {
          message.error(err.message, 5);
          setLoader(false);
        });
    }, limit);
  };

  function hasAvailableOption(currencyCode, option) {
    // const countryCode = ctzc
    //   .getAllISOByCurrencyOrSymbol("currency", currencyCode)
    //   .toString();
    // const country = addMoneyCountries.find((c) => c.country === countryCode);
    let country;
    if (currencyCode === "XAF") {
      country = addMoneyCountries.find((c) => c?.country === "CM");
    } else if (currencyCode === "XOF") {
      country = addMoneyCountries.find((c) => c?.country === "CI");
    } else {
      const countryCode = ctzc
        .getAllISOByCurrencyOrSymbol("currency", currencyCode)
        .toString();
      country = addMoneyCountries.find((c) => c?.country === countryCode);
    }

    if (country) {
      const availableOptions = country.addMoney;
      return availableOptions.includes(option);
    }

    return false; // Country not found in the array
  }

  const toolTipCurrency = (
    <div className="d-flex align-items-start">
      <img src={notifyWhite} alt="notify" />
      <h1 className="tooltip-h1 ms-2">
        This is the wallet you want to add money to
      </h1>
    </div>
  );

  const toolTipCurrencyTwo = (item) => (
    <div className="d-flex">
      <h1 className="tooltip-h1">
        Funding {item} wallet via {type} is not available
      </h1>
    </div>
  );

  return (
    <>
      <>
        {showWallets ? (
          <div className="banktransfer-others">
            <div>
              <>
                <h2 className="text-center">Funding details</h2>
                <br />
                <p className="wallet-addmoney-title mb-2">
                  Select top up wallet
                </p>
                <Tooltip placement="right" title={toolTipCurrency}>
                  <div
                    className="bank-transfer-modal"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={showOthers}
                    >
                      <img
                        src={defaultWallet.icon}
                        alt=""
                        className="curImage"
                      />

                      <p className="">
                        <span>{defaultWallet.currency} balance</span>
                        <br />
                        {defaultWallet.currency}&nbsp;
                        {formatMoney(defaultWallet.amount)}
                      </p>
                      <div className="ms-auto">
                        <i className="fa fa-angle-right showNow"></i>
                      </div>
                    </div>
                  </div>
                </Tooltip>

                <br />
                {defaultWallet.currency === "NGN" && type === "bank" ? (
                  <>
                    <br />
                    <div className="text-center">
                      <button
                        className="btn-btn-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => next(defaultWallet.currency)}
                      >
                        Continue
                      </button>
                    </div>
                  </>
                ) : defaultWallet.currency === "NGN" && type === "momo" ? (
                  <>
                    <br />
                    <div className="notify-ban-account mb-5">
                      <div className="d-flex align-items-start justify-content-between">
                        <img src={notify} alt="notify" />
                        <h1 className="header-bank">
                          Funding your {defaultWallet.currency} wallet via{" "}
                          {type}
                          &nbsp; is not available at this moment. You can
                          however fund via bank.
                        </h1>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn-btn-btn"
                        style={{ cursor: "pointer" }}
                        disabled={!(inputValue && paymentMethod)}
                      >
                        Continue
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="addmoney-form">
                      {hasAvailableOption(defaultWallet?.currency, type) ? (
                        showPayMent && (
                          <div>
                            <p className="my-2">Enter top up amount</p>
                            <Form>
                              <Input.Group compact style={{ width: "100%" }}>
                                <Select
                                  style={{ width: "30%" }}
                                  name="from"
                                  // onChange={(value) => setSelectValue(value)}
                                  defaultValue={defaultWallet.currency}
                                >
                                  <Option value={defaultWallet.currency}>
                                    {defaultWallet.currency}
                                  </Option>
                                </Select>
                                <InputNumber
                                  style={{ width: "70%" }}
                                  defaultValue="0.00"
                                  min="0"
                                  precision="2"
                                  value={inputValue}
                                  name="amount"
                                  onChange={(value) => {
                                    updateQueryOne(value);
                                  }}
                                />
                              </Input.Group>
                            </Form>
                            <br />
                            {showDetails && (
                              <div>
                                <div className="d-flex">
                                  <div>
                                    <p>{defaultWallet.currency} balance</p>
                                  </div>
                                  <div className="ms-auto">
                                    <p>
                                      {defaultWallet.currency}&nbsp;
                                      {formatMoney(defaultWallet.amount)}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <p>
                                      {quotationDetails?.currency} balance after
                                      top up
                                    </p>
                                  </div>
                                  <div className="ms-auto">
                                    <p>
                                      {quotationDetails?.currency}&nbsp;
                                      {formatMoney(
                                        quotationDetails?.new_balance
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <p>Account limit</p>
                                  </div>
                                  <div className="ms-auto">
                                    <p>
                                      {quotationDetails?.currency}&nbsp;
                                      {formatMoney(quotationDetails?.max_limit)}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <p>Maximum load amount</p>
                                  </div>
                                  <div className="ms-auto">
                                    <p>
                                      {quotationDetails?.currency}&nbsp;
                                      {formatMoney(
                                        quotationDetails?.max_load_amount
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <p>Minimum load amount</p>
                                  </div>
                                  <div className="ms-auto">
                                    <p>
                                      {quotationDetails?.currency}&nbsp;
                                      {formatMoney(
                                        quotationDetails?.min_load_amount
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <hr />
                                <div className="d-flex">
                                  <div>
                                    <p>Transaction fee</p>
                                  </div>
                                  <div className="ms-auto">
                                    <p>
                                      {quotationDetails?.currency}&nbsp;
                                      {formatMoney(quotationDetails?.charges)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      ) : (
                        <div className="notify-ban-account mb-5">
                          <div className="d-flex align-items-start justify-content-between">
                            <img src={notify} alt="notify" />
                            <h1 className="header-bank">
                              Funding your {defaultWallet.currency} wallet via
                              &nbsp;
                              {type}&nbsp; is not available at this moment. You
                              can however fund via mobile money.
                            </h1>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            </div>
            <div className="text-center">
              <button
                className="btn-btn-btn"
                disabled={!(inputValue && paymentMethod) || loader}
                onClick={() =>
                  next(defaultWallet.currency, inputValue, paymentMethod)
                }
              >
                {loader ? "Loading ..." : "Continue"}
              </button>
            </div>
          </div>
        ) : null}
      </>

      {showTabs ? (
        <div
          style={{ margin: "0 auto 0 auto", width: "80%" }}
          className="tabs-for-add-money"
        >
          <h2 className="text-center wallet-add-money">Choose wallet</h2>
          <br />
          <div>
            <Search
              viewSize="header-search"
              onChange={() => {}}
              placeholder="Search for wallet"
            />
            <br />
            <p>{fiatWallets.length} active wallets</p>
            <br />
            {wallets.map((wallet, index) =>
              wallet.enabled === true &&
              wallet.isCrypto === false &&
              !excludedCurrencies.includes(wallet.currency) ? (
                hasAvailableOption(wallet?.currency, type) ? (
                  <div
                    className="d-flex align-items-center wallet-add"
                    key={index}
                    onClick={() => selectedWallet(wallet)}
                  >
                    <img src={wallet.icon} alt="" className="img-flag" />
                    <p className="currency-type ms-3">
                      {wallet.currency} {formatMoney(wallet.amount)}
                      <br />
                      <span>{wallet.name}</span>
                    </p>
                    <div className="ms-auto">
                      <i className="fa fa-angle-right showNow"></i>
                    </div>
                  </div>
                ) : (
                  <div key={index}>
                    <Tooltip
                      placement="right"
                      title={toolTipCurrencyTwo(wallet.currency)}
                    >
                      <div
                        className="d-flex align-items-center wallet-add"
                        style={{ backgroundColor: "#E9EBED" }}
                        onClick={() => selectedWallet(wallet)}
                      >
                        <img src={wallet.icon} alt="" className="img-flag" />
                        <p className="currency-type ms-3">
                          {wallet.currency} {formatMoney(wallet.amount)}
                          <br />
                          <span>{wallet.name}</span>
                        </p>
                        <div className="ms-auto">
                          <i className="fa fa-angle-right showNow"></i>
                        </div>

                        <div
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "-8px",
                            padding: "0px 8px 0px 8px",
                            borderRadius: "24px",
                            border: "1px solid #e9ebed",
                            zIndex: 99,
                            fontFamily: "DM Sans",
                            fontSize: "12px",
                            fontWeight: 400,
                            background: "#F4F5F6",
                          }}
                        >
                          Not available
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                )
              ) : null
            )}
          </div>

          {/* <Tabs defaultActiveKey="1" onChange={callback} centered>
            <TabPane tab="Fiat" key="1">
            
              
            </TabPane>
            {isActive ? (
              <TabPane tab="Cryptocurrency" key="2">
        
                <Search
                  viewSize="header-search"
                  onChange={() => {}}
                  placeholder="Search  for wallet"
                />
                <br />
                <p>{cryptoWallets.length} active wallets</p>
                <div className="add-money-crypto-div">
                  {cryptoWallets.length === 0 ? (
                    <div className="text-center">
                      <img src={disableIcon} alt="disable" />
                      <br />
                      <br />
                      <h2 className="crypto-h2">
                        You have no active crypto wallet
                      </h2>
                      <p className="crypto-p">
                        Click on “Activate crypto wallet” to start transacting
                        with crypto
                      </p>
                      <br />
                      <br />
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn-btn-btn"
                          onClick={() => history.push("/wallets")}
                        >
                          Activate crypto wallet
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {wallets.map((wallet, index) =>
                        wallet.enabled === true && wallet.isCrypto === true ? (
                          <div
                            className="d-flex align-items-center wallet-add"
                            key={index}
                            onClick={() => selectedWallet(wallet)}
                          >
                            <img
                              src={wallet.icon}
                              alt=""
                              className="img-flag"
                            />
                            <p className="currency-type ms-3">
                              {wallet.currency} {formatMoney(wallet.amount)}
                              <br />
                              <span>{wallet.name}</span>
                            </p>
                            <div className="ms-auto">
                              <i className="fa fa-angle-right showNow"></i>
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  )}
                </div>
              </TabPane>
            ) : null}
          </Tabs> */}
        </div>
      ) : null}
    </>
  );
}
