import React from "react";

import logo from "../../../assets/images/v-card-logo.svg";
import masterCard from "../../../assets/images/master-card.svg";
import visaCard from "../../../assets/images/visa-logo.svg";
import moment from "moment";

export default function Fourth({ card, colors, cardModal, close }) {
  // console.log(card)
  function onOpenModal() {
    const newData = {
      ...card.newCard,
      totalCardSpend: 0, // This is the new field you want to add
    };
    cardModal(newData);
  }
  return (
    <div className="banktransfer-others">
      <div>
        <div
          className="virtual-card-design p-4 mt-3 mb-3"
          style={{ backgroundColor: colors[card?.newCard?.card?.color] }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <img src={logo} alt="Eversend logo" />
            <h2>VIRTUAL</h2>
          </div>
          <h1 className="mt-3">{card?.newCard?.card?.mask}</h1>
          <div className="d-flex align-items-center mt-3">
            <div>
              <p>Valid thru</p>
              <h3>{moment(card?.newCard?.card?.expiration).format("MM/YY")}</h3>
            </div>
            <div className="ms-3">
              <p>CVV</p>
              <h3>***</h3>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <h4>{card?.newCard?.card?.name}</h4>
            <img
              src={
                card?.newCard?.card?.brand === "Visa" ? visaCard : masterCard
              }
              alt="mastercard logo"
            />
          </div>
        </div>
        <div>
          <h1 className="wallet-addmoney-title mb-2 text-center">
            Card Created Successfully
          </h1>
          <p className="text-center">
            Your card is up and running, ready for your transactions
          </p>
        </div>
        <br />
      </div>

      <button
        className="ant-btn ant-btn-default  grey-btn btn-block"
        style={{ cursor: "pointer" }}
        onClick={onOpenModal}
      >
        View Card Details
      </button>
      <br />
      <br />
      <button
        className="btn-btn-btn"
        style={{ cursor: "pointer" }}
        onClick={close}
      >
        Continue
      </button>
    </div>
  );
}
