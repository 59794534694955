import React from "react";
import AccordionItem from "../Accordion";

export default function FAQ({ closeModalContinue }) {
  const items = [
    {
      title: "What is Eversend B2B?",
      shortDescription: "Short description for item 1",
      content: "Content for item 1",
    },
    {
      title: "What is Eversend B2B API?",
      shortDescription: "Short description for item 2",
      content: "Content for item 2",
    },
    {
      title: "Who can use Eversend B2B services?",
      shortDescription: "Short description for item 3",
      content: "Content for item 3",
    },
    {
      title: "How do i start transacting on Eversend B2B",
      shortDescription: "Short description for item 4",
      content: "Content for item 4",
    },
  ];

  return (
    <div className="welcome-container">
      <div className="welcome-container-main">
        <h1 className="welcome-container-main-h1">FAQs</h1>
        <h2 className="welcome-container-main-h2">
          Lets get you started, by answering a few questions you might have
          about Eversend B2B
        </h2>

        {items.map(({ title, shortDescription, content }, index) => (
          <div key={index}>
            <AccordionItem
              title={title}
              description={shortDescription}
              content={content}
            />
          </div>
        ))}
      </div>
      <div className="welcome-container-footer">
        <div className="d-flex align-items-center justify-content-between">
          {/* <p>
            Have more questions?
            <a href="https://eversend.co">join the help group</a>
          </p> */}
          <p>&nbsp;</p>
          <button
            type="submit"
            className="custom-modal__close-button"
            onClick={closeModalContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
