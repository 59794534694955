import React, { useState } from "react";
import StyledModal from "../../styled-modal";
import First from "./first";
import Second from "./second";
import Third from "./third";
import StepsWrapper from "../../../StepsWrapper";

export default function RequestMoneyModal({ show, onClose }) {
  //   const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [userDetails, setUserDetails] = useState();
  const [currencyDetails, setCurrencyDetails] = useState();

  const steps = [
    {
      title: "Eversend user info",
      content: <First next={nextFirst} userDetailsTwo={userDetails} />,
    },
    {
      title: "Request amount",
      content: (
        <Second
          next={nextSecond}
          currencyDetails={currencyDetails}
          prev={() => prev()}
        />
      ),
    },
    {
      title: "Confirm",
      content: (
        <Third
          next={nextThird}
          allData={{ userDetails, currencyDetails }}
          prev={() => prev()}
          close={onClose}
          newTrans={newTrans}
        />
      ),
    },
  ];

  function newTrans() {
    setUserDetails();
    setCurrencyDetails();
    setCurrent(0);
  }

  function nextFirst(userDetails) {
    setUserDetails(userDetails);
    setCurrent(current + 1);
  }

  function nextSecond(amountCurrency) {
    setCurrencyDetails(amountCurrency);
    setCurrent(current + 1);
  }

  function nextThird(amountCurrency) {
    setCurrencyDetails(amountCurrency);
    setCurrent(current + 1);
  }

  function prev() {
    setCurrent(current - 1);
  }

  function onCloseModal(item) {
    setCurrent(item);
    onClose();
  }
  console.log({ current });
  return (
    <StyledModal show={show} onClose={() => onCloseModal(0)}>
      <StepsWrapper
        title="Fund Wallet"
        currentStep={current}
        steps={steps}
        onGoBack={prev}
      >
        {steps[current].content}
      </StepsWrapper>
    </StyledModal>
  );
}
