import React, { useState, useEffect } from "react";
import { useDispatch } from "../../../utilities/hooks";
import makeAPICall from "../../../utilities/apiUtilsFetch";
import makeAPI from "../../../utilities/apiUtils";
import { fetchProfileAction } from "../../../services/actions";
import { message } from "antd";
import { ReactComponent as Success } from "../../../assets/images/confirm_success.svg";
import { ReactComponent as Rejected } from "../../../assets/images/rejected_warning.svg";
import upload from "../../../assets/images/Upload.svg";
import { ReactComponent as Cancel } from "../../../assets/images/cancel.svg";
import Loader from "../../../components/Loader";
import alert from "../../../assets/images/alert.svg";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_submit_kyb_document",
  },
};

export default function BusinessKYB({
  user,
  rejected,
  completed,
  uploaded,
  totalUpload,
}) {
  const dispatch = useDispatch();
  const [fileInputValues, setFileInputValues] = useState({});
  const [kyc, setKyc] = useState();
  const [rootKyc, setRootKyc] = useState([]);
  const [loadingKyc, setLoadingKyc] = useState();
  const [requiredDoc, setRequiredDoc] = useState([]);
  const [loading, setLoading] = useState({
    loading: false,
    key: 0,
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [name, setName] = useState("");

  const kycGet = () => {
    return makeAPICall({
      path: "kyc",
      method: "GET",
    })
      .then((res) => {
        setRootKyc(res.data);
        uploaded(res?.data?.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    kycGet();
    return makeAPICall({
      path: "kyc/document",
      method: "GET",
    })
      .then((res) => {
        setKyc(res.data);
        totalUpload(res.data.length);
        setRequiredDoc(res.data.filter((doc) => doc.isRequired));
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasRejectedStatus = rootKyc?.some((obj) => obj.status === "rejected");
    if (hasRejectedStatus) {
      rejected(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootKyc]);

  const handleForm = (e, item, index) => {
    // console.log(e, "test");
    setFileInputValues({
      ...fileInputValues,
      [e.target.name]: item,
    });
    const file = e.target.files[0];
    setName(file?.name);
    setLoading({ loading: true, key: index });
    if (file) {
      const fileType = file["type"];
      const validImageTypes = ["image/png", "image/jpeg", "application/pdf"];
      if (validImageTypes.includes(fileType)) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", item);

        return makeAPICall({
          path: "kyc",
          payload: formData,
          method: "POST",
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        })
          .then((res) => {
            setUploadProgress(0);
            message.success(`${item} Uploaded Successfully`);
            kycGet();
            setLoading({ loading: false, key: 0 });
          })
          .catch((err) => {
            setUploadProgress(0);
            message.error(err.message);
            setLoading({ loading: false, key: 0 });
          });
      } else {
        message.error("Invalid file type selected");
        setLoading({ loading: false, key: 0 });
      }
    }
  };

  const deleteUpload = (index) => {
    const data = {
      id: index,
    };
    return makeAPICall({
      path: "kyc",
      payload: data,
      method: "DELETE",
    })
      .then((res) => {
        kycGet();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const splitter = "/kycdocs/";

  const DocumentUpload = (item, index) => {
    const newItem = rootKyc.find((value) => value?.title === item.document);
    const fileName = newItem?.url.slice(
      newItem?.url.indexOf(splitter) + splitter.length
    );
    return (
      <div className="item-flex" key={index}>
        <div className="d-flex align-items-center justify-content-between">
          <h1>{item.document}</h1>
          {newItem?.status === "rejected" ? (
            <span className="rejected-text">Rejected</span>
          ) : null}
        </div>
        {newItem?.status === "pending" ? (
          <div className="on-top" onClick={() => deleteUpload(newItem.id)}>
            <Cancel />
          </div>
        ) : null}

        <label
          className={`custom-file-upload ${
            newItem?.status === "rejected" ? "rejected-border" : ""
          }`}
        >
          <div
            className={`${
              loading.loading && loading.key === index
                ? "upload-progress-bar"
                : ""
            }`}
            style={{ width: `${uploadProgress}%` }}
          ></div>
          <input
            key={item.id}
            name={item.document}
            type="file"
            onChange={(e) => handleForm(e, item.document, index)}
            disabled={
              newItem?.title === item.document && newItem?.status === "approved"
                ? true
                : false
            }
            accept=".pdf, .png, .jpeg, .jpg"
            className="file-upload-custom-input"
          />
          {newItem?.title === item.document &&
          newItem?.status === "approved" ? (
            <div className="mt-1">
              <span className="d-flex align-items-center justify-content-between">
                <p>
                  {fileName.length > 20
                    ? fileName.substring(0, 20) + "..."
                    : fileName}
                </p>
                <div className="d-flex align-items-center">
                  <Success />
                </div>
              </span>
            </div>
          ) : newItem?.title === item.document &&
            newItem?.status === "rejected" ? (
            <span>
              {loading.loading && loading.key === index ? (
                <div className="mt-1">
                  <span className="d-flex align-items-center justify-content-between">
                    <p>
                      {name?.length > 20
                        ? name?.substring(0, 20) + "..."
                        : name}
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          border: "1px solid #CBCAE5",
                          height: "24px",
                          width: "2px",
                          margin: "0 10px",
                        }}
                      ></div>
                      <Cancel />
                    </div>
                  </span>
                </div>
              ) : (
                <div className="mt-1">
                  <span className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span style={{ marginRight: "10px", marginTop: "5px" }}>
                        <Rejected />
                      </span>

                      <p>
                        {fileName.length > 20
                          ? fileName.substring(0, 20) + "..."
                          : fileName}
                      </p>
                    </div>

                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          border: "1px solid #CBCAE5",
                          height: "24px",
                          width: "2px",
                          margin: "0 10px",
                        }}
                      ></div>
                      <img src={upload} alt="upload" />
                    </div>
                  </span>
                </div>
              )}
            </span>
          ) : (
            <span>
              {loading.loading && loading.key === index ? (
                <div className="mt-1">
                  <span className="d-flex align-items-center justify-content-between">
                    <p>
                      {name?.length > 20
                        ? name?.substring(0, 20) + "..."
                        : name}
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          border: "1px solid #CBCAE5",
                          height: "24px",
                          width: "2px",
                          margin: "0 10px",
                        }}
                      ></div>
                      <Cancel />
                    </div>
                  </span>
                </div>
              ) : (
                <span>
                  {newItem?.url ? (
                    <div className="mt-1">
                      <span className="d-flex align-items-center justify-content-between">
                        <p>
                          {fileName.length > 20
                            ? fileName.substring(0, 20) + "..."
                            : fileName}
                        </p>
                        <div className="d-flex align-items-center">
                          <Success />
                          <div
                            style={{
                              border: "1px solid #CBCAE5",
                              height: "24px",
                              width: "2px",
                              margin: "0 30px  0 10px",
                            }}
                          ></div>
                        </div>
                      </span>
                    </div>
                  ) : (
                    <div className="centered-items mt-1">
                      <span className="d-flex align-items-center">
                        <img src={upload} alt="upload" />
                        <p>Click to upload file</p>
                      </span>
                    </div>
                  )}
                </span>
              )}
            </span>
          )}
        </label>
      </div>
    );
  };

  const handleSubmit = () => {
    var data = {
      kybStatus: "submitted",
    };
    // data.append("kycStatus", "submitted");
    setLoadingKyc(true);
    return makeAPI({
      path: "profile",
      payload: data,
      method: "PUT",
    })
      .then((res) => {
        // console.log(res);
        setLoadingKyc(false);
        message.success(
          "We have received all your documents, we will review and let you know"
        );
        TagManager.dataLayer(tagManagerArgs);
        dispatch(fetchProfileAction());
        completed();
      })
      .catch((err) => {
        message.error(err.message);
        setLoadingKyc(false);
      });
  };

  const arraysMatch = requiredDoc.every((array2Item) => {
    return rootKyc.find(
      (array1Object) => array1Object.title === array2Item.document
    );
  });

  useEffect(() => {
    if (
      user?.account?.kybStatus === "submitted" ||
      user?.account?.kybStatus === "verified"
    ) {
      completed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <div className="onboarding-card-form-details">
        <div className="business-form-fields">
          <h1>KYB (Know your business)</h1>
          <p>Provide more information about your business.</p>
          <h2>{kyc?.length} Uploads</h2>
          <div className="d-flex align-items-start justify-content-between note-business">
            <img src={alert} alt="alert" style={{ marginRight: "10px" }} />
            <h2>
              Ensure that documents are up to date and are uploaded in the
              correct field.
            </h2>
          </div>
          {kyc && rootKyc ? (
            <div>{kyc.map((item, index) => DocumentUpload(item, index))}</div>
          ) : (
            <Loader dark={false} />
          )}
        </div>
      </div>
      <div className="text-right">
        <button
          type="submit"
          className="custom-modal__close-button"
          onClick={handleSubmit}
          disabled={arraysMatch ? false : true}
        >
          {loadingKyc ? (
            <span className="d-flex align-items-center justify-content-between">
              <Loader dark={false} /> Continue
            </span>
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </div>
  );
}
