import React, { useState, useEffect } from "react";
// import ProgressBar from "./ProgressBar"
import MetaMap from "./MetaMap";
import BusinessName from "./BusinessRegistration/BusinessName";
import BusinessAddress from "./BusinessRegistration/BusinessAddress";
import BusinessType from "./BusinessRegistration/BusinessType";
import TransactionPin from "./TransactionPin";
import BusinessKYB from "./BusinessKYB";
// import makeAPICall from "../../../utilities/apiUtils";
import { ReactComponent as Success } from "../../../assets/images/confirm_success.svg";
import { ReactComponent as Rejected } from "../../../assets/images/progress_rejected.svg";
import completeIcon from "../../../assets/images/Group 195.png";

export default function OnboardingEntry({ user, show, setOnBoardView }) {
  // ReactGA.ga((tracker) => {
  //   localStorage.setItem("GA_LOCAL_STORAGE_KEY", tracker.get("clientId"));
  // });
  const [activeTab, setActiveTab] = useState(0);
  const [verifiedIssue, setVerifiedIssue] = useState(true);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [totalUploadIndex, setTotalUploadIndex] = useState(0);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [inputValues, setInputValues] = useState({
    name: "",
    bvn: "",
    town: "",
    address: "",
    website: "",
    pin: "",
    businessCountry: "",
    businessType: "",
  });
  const [tabs, setTabs] = useState([
    {
      title: "Individual proof of identity",
      content: <MetaMap user={user} handleNextTab={handleNextTab} />,
      completed: false,
      rejected: false,
    },
    {
      title: "Business registration",
      content: "This is the content of Tab 2.",
      subTabs: [
        {
          title: "Business name",
          content: (
            <BusinessName
              user={user}
              onFormChange={handleFormChange}
              handleSubTab={handleNextSubTab}
            />
          ),
          completed: false,
        },
        {
          title: "Business type",
          content: (
            <BusinessType
              user={user}
              onFormChange={handleFormChangeType}
              handleSubTab={handleNextSubTab}
            />
          ),
          completed: false,
        },
        {
          title: "Business address",
          content: (
            <BusinessAddress
              user={user}
              onFormChange={handleFormChangeTwo}
              handleNextTab={handleNextTab}
            />
          ),
          completed: false,
        },
      ],
      completed: false,
      rejected: false,
    },
    {
      title: "Transaction pin",
      content: (
        <TransactionPin
          user={user}
          onFormChange={handleNextTab}
          uploaded={uploaded}
          totalUpload={totalUpload}
        />
      ),
      completed: false,
      rejected: false,
    },
    {
      title: "KYB (Know your business)",
      content: (
        <BusinessKYB
          user={user}
          completed={handleKYBSubmit}
          rejected={handleNextTabRejected}
          uploaded={uploaded}
          totalUpload={totalUpload}
        />
      ),
      completed: false,
      rejected: false,
    },
  ]);

  // function googleAnalytics(payload) {
  //   return makeAPICallGoogle({
  //     payload: payload,
  //     method: "POST",
  //   })
  //     .then((res) => {
  //       localStorage.removeItem("GA_LOCAL_STORAGE_KEY");
  //       localStorage.setItem("MATI_VERIFIED", true);
  //     })
  //     .catch((err) => {
  //       message.error(err.message);
  //     });
  // }

  useEffect(() => {
    if (user?.account && user?.account.isVerified === false)
      setVerifiedIssue(false);
  }, [user]);

  function handleNextTab(tabIndex) {
    const newTabs = [...tabs];
    const currentTab = newTabs[tabIndex];
    currentTab.completed = true;

    const nextTabIndex = tabIndex + 1;
    const nextTab = newTabs[nextTabIndex];

    setActiveTab(nextTabIndex);

    if (nextTab && nextTab.subTabs && nextTab.subTabs.length > 0) {
      setActiveSubTab(0);
    }

    setTabs(newTabs);
  }

  function handleNextSubTab(tabIndex, subTabIndex) {
    const newTabs = [...tabs];
    const currentTab = newTabs[tabIndex];
    const currentSubTab = currentTab.subTabs[subTabIndex];
    const isLastSubTab = currentSubTab.isLastSubTab;

    currentSubTab.completed = true;

    if (isLastSubTab) {
      currentTab.completed = true;
      setActiveTab(tabIndex + 1);
      setActiveSubTab(0);
    } else {
      setActiveSubTab(subTabIndex + 1);
    }

    setTabs(newTabs);
  }

  function handleFormChange(formDetails) {
    setInputValues({
      ...inputValues,
      name: formDetails.name,
      bvn: formDetails.bvn,
    });
    const data = {
      name: formDetails.name,
      bvn: formDetails.bvn,
      town: "",
      address: "",
      website: "",
      businessCountry: "",
      businessType: "",
    };
    localStorage.setItem("formFieldsStorage", JSON.stringify(data));
  }

  function handleFormChangeType(selected, type) {
    setInputValues({
      ...inputValues,
      businessCountry: selected,
      businessType: type,
    });
    const newData = { businessCountry: selected, businessType: type };
    const currentFormFields = JSON.parse(
      localStorage.getItem("formFieldsStorage")
    );

    const mergedFormFields = { ...currentFormFields, ...newData };
    localStorage.setItem("formFieldsStorage", JSON.stringify(mergedFormFields));
  }

  function handleFormChangeTwo(formValues) {
    setInputValues({
      ...inputValues,
      town: formValues.town,
      address: formValues.address,
      website: formValues.website,
    });
    const newData = {
      town: formValues.town,
      address: formValues.address,
      website: formValues.website,
    };
    const currentFormFields = JSON.parse(
      localStorage.getItem("formFieldsStorage")
    );

    const mergedFormFields = { ...currentFormFields, ...newData };
    localStorage.setItem("formFieldsStorage", JSON.stringify(mergedFormFields));
  }

  function handleNextTabRejected(tabIndex) {
    const newTabs = [...tabs];
    const currentTab = newTabs[tabIndex];
    currentTab.rejected = true;

    setActiveTab(tabIndex);
    setTabs(newTabs);
  }

  function handleKYBSubmit() {
    setOnBoardView(false);
  }

  function uploaded(index) {
    setUploadIndex(index);
  }

  function totalUpload(index) {
    setTotalUploadIndex(index);
  }

  const progress =
    totalUploadIndex === 0 ? 0 : (uploadIndex / totalUploadIndex) * 60;

  function formatProgress(progress) {
    if (typeof progress === "number") {
      return progress.toFixed(0);
    } else {
      // Return a default value or handle the case where progress is not a number
      return "0"; // Or any other default value
    }
  }

  return (
    <div>
      {verifiedIssue ? (
        <div className="custom-step-form">
          <div className="row">
            <div className="col-md-5">
              <div className="custom-step-card">
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={`custom-modal__tab ${
                      activeTab === index ? "active" : ""
                    } ${tab.completed ? "completed" : ""} ${
                      tab.rejected ? "rejected" : ""
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      {tab.completed ? (
                        <span style={{ marginTop: "-8px" }}>
                          <Success />
                        </span>
                      ) : tab.rejected ? (
                        <span
                          className="check-svg-required"
                          style={{ marginTop: "-8px" }}
                        >
                          <Rejected />
                        </span>
                      ) : (
                        <h1 className="p-count">{index + 1}</h1>
                      )}
                      <p>{tab.title}</p>
                      {tab.rejected ? (
                        <h4
                          className="rejected-text ms-auto"
                          style={{ marginTop: "-8px" }}
                        >
                          Action required
                        </h4>
                      ) : (
                        <div style={{ marginTop: "-10px" }} className="ms-auto">
                          <div className="push-to-right ms-auto">
                            <div className="custom__progress-bar">
                              <div
                                className="progress-step"
                                style={{
                                  width: `${tab.completed ? "60" : progress}px`,
                                }}
                              />
                            </div>
                          </div>

                          {tab.completed ? (
                            <h3>Completed</h3>
                          ) : (
                            <h3>{formatProgress(progress)}% Completed</h3>
                          )}
                        </div>
                      )}
                    </div>
                    {activeTab === index && tab.subTabs && (
                      <div className="custom__sub-tabs">
                        {tab.subTabs.map((subTab, subIndex) => (
                          <div
                            key={subIndex}
                            className={`custom__sub-tab ${
                              activeSubTab === subIndex ? "active" : ""
                            } ${subTab.completed ? "completed" : ""}`}
                          >
                            {subTab.title}
                          </div>
                        ))}
                      </div>
                    )}
                    <hr className="custom-hr" />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-7">
              <div>
                {tabs[activeTab].subTabs ? (
                  <div className="modal__sub-tab-content">
                    {tabs[activeTab].subTabs[activeSubTab].content}
                  </div>
                ) : (
                  tabs[activeTab].content
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={completeIcon} alt="" />
              <h1>Verification in progress</h1>
              <p>
                We are currently verifying your account, customer support will
                reach out to you. This can take up to 24 - 48 hours. please
                check your inbox for a notification message
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Contact support
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
