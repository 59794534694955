import React, { useState, useEffect } from "react";
import { message } from "antd";
import PasswordStrengthBar from "react-password-strength-bar";
import { ReactComponent as Check } from "../../../assets/images/password-check-active.svg";
import { ReactComponent as Uncheck } from "../../../assets/images/password-check-inactive.svg";
import ClosedEye from "../../../assets/images/Eye_Closed.svg";
import OpenEye from "../../../assets/images/Eye_Open.svg";

export default function FifthForm({ title, goToNextStep, inputDetails }) {
  const [passwordValues, setPasswordValues] = useState({
    newPassword: inputDetails?.password ? inputDetails?.password : "",
    confirmNewPassword: inputDetails?.password ? inputDetails?.password : "",
  });
  const [inputType, setInputType] = useState("password");
  const [inputTypeOne, setInputTypeOne] = useState("password");
  const [isPasswordMatch, setIsPasswordMatch] = useState("idle");
  const [isMin, setIsMin] = useState(false);
  const [isAlphaNum, setIsAlphNum] = useState(false);
  // const [isMatch, setIsMatch] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  function handleChange(event) {
    setPasswordValues({
      ...passwordValues,
      [event.target.name]: event.target.value.trim(),
    });
  }

  useEffect(() => {
    if (
      passwordValues.confirmNewPassword !== "" &&
      passwordValues.newPassword === passwordValues.confirmNewPassword
    ) {
      setIsPasswordMatch("matched");
    } else if (passwordValues.confirmNewPassword === "") {
      setIsPasswordMatch("idle");
    } else {
      setIsPasswordMatch("noMatch");
    }
  }, [passwordValues.newPassword, passwordValues.confirmNewPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    const reg = /[0-9]/g;
    const test = reg.test(pass);
    if (test) {
      setIsAlphNum(true);
    } else {
      setIsAlphNum(false);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    if (pass.length < 8) {
      setIsMin(false);
    } else {
      setIsMin(true);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    const uppercaseReg = /[A-Z]/g;
    const uppercaseTest = uppercaseReg.test(pass);
    if (uppercaseTest) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    const lowercaseReg = /[a-z]/g;
    const lowercaseTest = lowercaseReg.test(pass);
    if (lowercaseTest) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
  }, [passwordValues.newPassword]);

  useEffect(() => {
    const pass = passwordValues.newPassword;
    const reg = /[!@#$%^&*(),.?":{}|<>]/g;
    const test = reg.test(pass);
    if (test) {
      setIsSpecialCharacter(true);
    } else {
      setIsSpecialCharacter(false);
    }
  }, [passwordValues.newPassword]);

  // useEffect(() => {
  //   if (
  //     passwordValues.newPassword !== "" &&
  //     passwordValues.confirmNewPassword !== "" &&
  //     passwordValues.newPassword === passwordValues.confirmNewPassword
  //   ) {
  //     setIsMatch(true);
  //   } else {
  //     setIsMatch(false);
  //   }
  // }, [passwordValues.newPassword, passwordValues.confirmNewPassword]);

  useEffect(() => {
    if (
      isMin &&
      isAlphaNum &&
      isLowerCase &&
      isSpecialCharacter &&
      isUpperCase
    ) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [isMin, isAlphaNum, isLowerCase, isSpecialCharacter, isUpperCase]);

  function handleNext() {
    if (passwordValues.newPassword === passwordValues.confirmNewPassword) {
      goToNextStep(passwordValues.newPassword);
    } else {
      message.error("Password does not match", 2);
    }
  }

  const togglePasswordVisibility = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const togglePasswordVisibilityOne = () => {
    setInputTypeOne(inputTypeOne === "password" ? "text" : "password");
  };

  return (
    <div>
      <h2>{title}</h2>
      <div className="form-group">
        <label
          htmlFor="password"
          style={{
            color: isPasswordMatch === "noMatch" ? "#B11030" : "#1C223C",
          }}
          className="label-form"
        >
          Create password
        </label>
        <div style={{ position: "relative" }}>
          <input
            type={inputTypeOne}
            value={passwordValues.newPassword}
            onChange={handleChange}
            name="newPassword"
            placeholder="Create secure password"
            className="reg-input"
          />
          <button
            onClick={togglePasswordVisibilityOne}
            style={{
              outline: "none",
              background: "transparent",
              position: "absolute",
              right: "10px",
              top: "16px",
              width: "20px",
              border: "none",
            }}
          >
            {inputTypeOne === "password" ? (
              <img src={ClosedEye} alt="close" />
            ) : (
              <img src={OpenEye} alt="open" />
            )}
          </button>
        </div>
      </div>
      <PasswordStrengthBar
        className="password__checker"
        password={passwordValues.newPassword}
      />
      {passwordValues.newPassword.length > 0 && (
        <div>
          <p className="password__check--text-p mb-2">Password must contain</p>
          <ul className="card-check color-black no-card--check">
            <li
              className={`flex password__check--text ${isMin ? "isTrue" : ""}`}
            >
              <span className="me-2">{isMin ? <Check /> : <Uncheck />}</span>8
              characters minimum
            </li>
            <li
              className={`flex password__check--text ${isMin ? "isTrue" : ""}`}
            >
              <span className="me-2">
                {isUpperCase ? <Check /> : <Uncheck />}
              </span>
              A capital / uppercase letter
            </li>
            <li
              className={`flex password__check--text ${isMin ? "isTrue" : ""}`}
            >
              <span className="me-2">
                {isLowerCase ? <Check /> : <Uncheck />}
              </span>
              A lowercase letter
            </li>
            <li
              className={`flex password__check--text ${
                isAlphaNum ? "isTrue" : ""
              }`}
            >
              <span className="me-2">
                {isAlphaNum ? <Check /> : <Uncheck />}
              </span>
              One number
            </li>
            {/* <li
              className={`flex password__check--text ${
                isMatch ? "isTrue" : ""
              }`}
            >
              <span className="me-2">{isMatch ? <Check /> : <Uncheck />}</span>
              Match with your confirm password
            </li> */}
            <li
              className={`flex password__check--text ${
                isAlphaNum ? "isTrue" : ""
              }`}
            >
              <span className="me-2">
                {isSpecialCharacter ? <Check /> : <Uncheck />}
              </span>
              A special character e.g, %, @, $, #, ^, &, *, ?, |
            </li>
          </ul>
        </div>
      )}
      <div className="form-group">
        <label
          htmlFor="passwordTwo"
          style={{
            color: isPasswordMatch === "noMatch" ? "#B11030" : "#1C223C",
          }}
          className="label-form"
        >
          Confirm password
        </label>
        <div style={{ position: "relative" }}>
          <input
            type={inputType}
            name="confirmNewPassword"
            value={passwordValues.confirmNewPassword}
            onChange={handleChange}
            placeholder="Create secure password"
            className="reg-input"
          />
          <button
            onClick={togglePasswordVisibility}
            style={{
              outline: "none",
              background: "transparent",
              position: "absolute",
              right: "10px",
              top: "16px",
              width: "20px",
              border: "none",
            }}
          >
            {inputType === "password" ? (
              <img src={ClosedEye} alt="close" />
            ) : (
              <img src={OpenEye} alt="open" />
            )}
          </button>
        </div>
      </div>
      {isPasswordMatch === "noMatch" ? (
        <p
          className="color-negative text-sm text-normal"
          style={{ color: "#B11030" }}
        >
          Passwords do not match
        </p>
      ) : null}
      <br />

      <button
        type="submit"
        disabled={!isAuthorized}
        onClick={handleNext}
        className="submit-reg"
      >
        Continue
      </button>
    </div>
  );
}
