import RightArrow from "../../assets/images/rightarrow.svg";

export default function PayoutButton(props) {
  return (
    <div
      className="card-btn bg-white flex justify-start align-center mb-3"
      onClick={props.handleClick}
    >
      <img src={props.icon} alt={props.alt} className="me-4" />
      {props.title}
      <img className="justify-self-end" src={RightArrow} alt={props.alt} />
    </div>
  );
}
