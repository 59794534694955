import React from "react";
import notify from "../../../assets/images/notify-bank.svg";
import { Typography } from "antd";

const { Paragraph } = Typography;

export default function NigeriaSecond({ bankDetails, prev, closeModal }) {
  return (
    <>
      <div className="banktransfer-others">
        <h2 className="text-center">
          {bankDetails.bank} account has been added
        </h2>
        <p className="text-center">
          Your new account has been added Make a tranfer from your local bank or
          bank app to the account below.
        </p>
        <br />
        <div className="d-flex">
          <div>
            <p className="naija-account-number-p">Bank Name</p>
            <h1 className="naija-account-number-h1">{bankDetails.bank}</h1>
          </div>
          <div className="ms-auto align-self-center">
            <Paragraph copyable={{ text: bankDetails.bank }}></Paragraph>
            {/* <img src={copy} alt="copy icon" /> */}
          </div>
        </div>
        <br />
        <div className="d-flex">
          <div>
            <p className="naija-account-number-p">Account name</p>
            <h1 className="naija-account-number-h1">
              {bankDetails.accountName}
            </h1>
          </div>
          <div className="ms-auto align-self-center">
            <Paragraph copyable={{ text: bankDetails.accountName }}></Paragraph>
          </div>
        </div>
        <br />
        <div className="d-flex">
          <div>
            <p className="naija-account-number-p">Account number</p>
            <h1 className="naija-account-number-h1">
              {bankDetails.accountNumber}
            </h1>
          </div>
          <div className="ms-auto align-self-center">
            <Paragraph
              copyable={{ text: bankDetails.accountNumber }}
            ></Paragraph>
          </div>
        </div>

        <br />
        <div className="notify-ban-account">
          <div className="d-flex">
            <img src={notify} alt="notify" />
            <h1 className="header-bank">
              Important! <br />
              <span className="header-bank-par">
                Transfers usually arrive your eversend wallet within 5 minutes
              </span>
            </h1>
          </div>
        </div>
        <br />
        <div className="text-center">
          <button className="btn-btn-play" onClick={prev}>
            Choose another account
          </button>
        </div>
        <br />
        <div className="text-center">
          <button className="btn-btn-btn" onClick={closeModal}>
            Return to dashboard
          </button>
        </div>
      </div>
    </>
  );
}
