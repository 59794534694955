import React, { useState } from "react";
import { message } from "antd";
import "../pay.css";
import StyledModal from "../../styled-modal";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Four";
import { useDispatch } from "../../../../utilities/hooks";
import {
  deletePayoutAction,
  deletePayoutQuotationAction,
  resetQuotationParams,
} from "../../../../services/actions";

import AdditionalInformation from "../AdditionalInformation";
import StepsWrapper from "../../../StepsWrapper";
import styles from "./beneficiary.module.scss";
import { Formik } from "formik";
import { useSelector } from "react-redux";

export default function BeneficiaryModal({
  show,
  onClose,
  beneficiary = null,
  isFirstTime,
}) {
  const [current, setCurrent] = useState(0);
  const [showNow, setShowNow] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState();
  const [currencyDetails, setCurrencyDetails] = useState();
  const [exchange, setExchange] = useState();
  const [paymentType, setPaymentType] = useState("");
  const [payOutData, setPayOutData] = useState({});
  const { accountSummary } = useSelector((state) => state.analyticsReducer);

  const dispatch = useDispatch();

  const [difference, setDifference] = useState({
    startTime: Date.now(),
    endTime: Date.now() + 30000,
  });

  const steps = [
    {
      title: "Choose beneficiary",
      content: (
        <First
          onClick={nextFirst}
          selectedBeneficiary={beneficiary}
          isFirstTime={isFirstTime}
        />
      ),
    },
    {
      title: "Payout details",
      content: (
        <Second
          onClick={nextSecond}
          paymentType={paymentType}
          payOutData={payOutData}
          onSetPayOutData={(value) => setPayOutData(value)}
          onNumberChange={(value) => setDifference(value)}
        />
      ),
    },

    {
      title: "Additional information",
      content: <AdditionalInformation onClick={next} />,
    },
    {
      title: "Review payout",
      content: (
        <Third
          currencyDetails={currencyDetails}
          onClick={nextLast}
          paymentType={paymentType}
          goBack={() => prev()}
          difference={difference}
          payOutData={payOutData}
        />
      ),
    },
    {
      title: "Receipt",
      content: (
        <Fourth
          transDetails={transactionDetails}
          exchange={exchange}
          newPayout={newPayout}
          gotoHome={gotoHome}
        />
      ),
    },
  ];

  function resetInputs() {
    setShowNow(false);
    setCurrencyDetails();
    setTransactionDetails();
    dispatch(deletePayoutAction());
    dispatch(deletePayoutQuotationAction());
  }

  function next() {
    setCurrent(current + 1);
    message.destroy();
  }

  function nextFirst(paymentType) {
    setPaymentType(paymentType);
    setShowNow(true);
    dispatch(deletePayoutAction());
    dispatch(deletePayoutQuotationAction());
    next();
  }

  function nextSecond(payoutQuotation) {
    setCurrencyDetails(payoutQuotation);
    setShowNow(false);
    next();
  }

  function nextLast(payout, optionTwo) {
    setTransactionDetails(payout);
    setExchange(optionTwo);
    next();
  }

  function prev() {
    setCurrent(current - 1);
    if (current - 1 === 0) {
      dispatch(resetQuotationParams());
      dispatch(deletePayoutQuotationAction());
    }
  }

  function onCloseModal(item) {
    setCurrent(item);
    dispatch(resetQuotationParams());
    resetInputs();
    onClose();
  }

  function newPayout() {
    dispatch(resetQuotationParams());
    resetInputs();
    setCurrent(0);
  }

  function gotoHome() {
    resetInputs();
    dispatch(resetQuotationParams());
    onClose();
  }

  const handleNav = (value, resetForm) => {
    if (value === 0) {
      resetForm();
    }
    if (value > current) {
      return;
    }
    setCurrent(value);
  };

  return (
    <StyledModal show={show} onClose={() => onCloseModal(0)}>
      <Formik
        initialValues={{
          beneficiary,
          paymentType: "",
          currency: accountSummary?.currency,
          amount: "",
          to: "",
          reason: "",
          selectedBeneficiary: null,
        }}
      >
        {({ resetForm, values: { selectedBeneficiary } }) => (
          <StepsWrapper
            title="Payouts"
            steps={steps}
            currentStep={current}
            onChange={(value) => handleNav(value, resetForm)}
            onGoBack={() => prev()}
            contentClassName={selectedBeneficiary && styles.contentWrapper}
            hideBackButton={current === 4}
          >
            {console.log({ selectedBeneficiary })}
            <div className={styles.content}>{steps[current].content}</div>

            {showNow && selectedBeneficiary && (
              <div className={styles.cardWrapper}>
                <div className={styles.card}>
                  <div className="d-flex">
                    <div className="table-in">
                      {selectedBeneficiary.firstName?.charAt(0) +
                        selectedBeneficiary.lastName?.charAt(0)}
                    </div>
                    <p className="ms-auto">&nbsp;</p>
                  </div>

                  <p className="benefit-name" style={{ fontWeight: "bold" }}>
                    Beneficiary Name
                  </p>
                  <p className="benefit-name" style={{ marginBottom: "10px" }}>
                    {selectedBeneficiary.firstName}&nbsp;
                    {selectedBeneficiary.lastName}
                  </p>

                  <p className="benefit-name" style={{ fontWeight: "bold" }}>
                    Payout type
                  </p>
                  <p className="benefit-name" style={{ marginBottom: "10px" }}>
                    {paymentType}
                  </p>

                  {selectedBeneficiary.isBank &&
                    selectedBeneficiary.bankAccountNumber && (
                      <>
                        <p
                          className="benefit-name"
                          style={{ fontWeight: "bold" }}
                        >
                          Bank Name
                        </p>
                        <p className="benefit-name">
                          {selectedBeneficiary.bankName}
                        </p>

                        <p
                          className="benefit-name"
                          style={{ fontWeight: "bold" }}
                        >
                          Bank Account Name
                        </p>
                        <p className="benefit-name">
                          {selectedBeneficiary?.bankAccountName}
                        </p>

                        <p
                          className="benefit-name"
                          style={{ fontWeight: "bold" }}
                        >
                          Bank Account Number
                        </p>
                        <p className="benefit-name">
                          {selectedBeneficiary?.bankAccountNumber}
                        </p>
                      </>
                    )}

                  <p className="benefit-name" style={{ fontWeight: "bold" }}>
                    Phone number
                  </p>
                  <p className="benefit-name">
                    {selectedBeneficiary.phoneNumber}
                  </p>
                </div>
              </div>
            )}
          </StepsWrapper>
        )}
      </Formik>
    </StyledModal>
  );
}
