import React, { useState, useEffect, useRef } from "react";
import downArrow from "../../assets/images/Chevron_down.svg";
import upArrow from "../../assets/images/Chevron_up.svg";
import { ReactComponent as AddIcon } from "../../assets/images/plus-add-sign.svg";
import "./customSelect.css";
import makeAPICall from "../../utilities/apiUtils";
import Loader from "../loaders/loading";
// import { message } from "antd";
// import { Tag } from "antd";
// import displayErrorMessage from "../notifyBox";
// import { key } from "../../utilities/helperFunctions";

const CustomSelect = ({ options = null, defaultValue, handleSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    defaultValue ? defaultValue : "Select a user"
  );
  const dropdownRef = useRef();
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState(options?.users);
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState(options?.total);
  const [pageNumber, setPageNumber] = useState(options?.page);

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectValue = (country) => {
    if (country === "add") {
      setIsOpen(false);
      handleSelect(country);
    } else {
      setSelectedValue(`${country.firstName} ${country.lastName}`);
      handleSelect(country);
      setIsOpen(false);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);

    if (searchTerm === "") {
      setFilteredItems(options.users);
    } else {
      const newFilteredItems = options?.users?.filter((item) => {
        const firstNameMatch = item.firstName
          ? item.firstName.toLowerCase().startsWith(searchTerm.toLowerCase())
          : false;

        const lastNameMatch = item.lastName
          ? item.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
          : false;

        return firstNameMatch || lastNameMatch;
      });
      setFilteredItems(newFilteredItems);
    }
  };

  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  const handleScroll = (e) => {
    const ul = e.target; // Reference to the ul element
    if (ul.scrollTop + ul.clientHeight >= ul.scrollHeight - 10) {
      if (filteredItems.length < totalData && !isLoading) {
        // Set isLoading to true to prevent multiple fetches at once
        const nextPageNumber = pageNumber + 1;
        const params = {
          page: nextPageNumber,
        };
        setIsLoading(true);
        return makeAPICall({
          path: `cards/getUsers`,
          method: "GET",
          params,
        })
          .then((res) => {
            setIsLoading(false); // Reset isLoading when data is loaded
            setPageNumber(res.data.page);
            setTotalData(res.data.total);
            setFilteredItems(res.data.users);
          })
          .catch((err) => {
            setIsLoading(false); // Reset isLoading when data is loaded
          });
        // Fetch more data and append it to loadedData
        // Update loadedData and totalData as needed
      }
    }
  };

  return (
    <div className="select-phone-input" ref={dropdownRef}>
      <div className="drpdown">
        <button onClick={toggleDropdown} className="drpdown-button">
          <span>{selectedValue}</span>
          <img
            src={isOpen ? upArrow : downArrow}
            alt="down"
            className="arrow-btn"
          />
        </button>
        {isOpen && (
          <ul className="drpdown-menu" onScroll={handleScroll}>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
              className="search-input"
            />
            <li
              onClick={() => selectValue("add")}
              className="drpdown-item-first mt-2 d-flex align-items-center"
            >
              <AddIcon />
              <span className="ms-2">Add new a card user</span>
            </li>
            <hr />
            {isLoading ? (
              <Loader />
            ) : (
              <span>
                {filteredItems?.map((country) => (
                  <li
                    key={country.id}
                    onClick={() => selectValue(country)}
                    className="drpdown-item mt-2"
                  >
                    <div>
                      {country?.firstName} {country?.lastName}
                    </div>
                    {/* <div>
                  {country?.role ? (
                    <Tag
                      color="#E2FCCC"
                      style={{
                        color: "#0F730E",
                        border: "1px solid #99E558",
                        borderRadius: "20px",
                      }}
                    >
                      {capitalizeFirstLetter(country?.role || "")}
                    </Tag>
                  ) : (
                    <Tag
                      color="#EEEEFD"
                      style={{
                        color: "#3127F1",
                        border: "1px solid #7D77F6",
                        borderRadius: "20px",
                      }}
                    >
                      Card user
                    </Tag>
                  )}
                </div> */}
                  </li>
                ))}
              </span>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
