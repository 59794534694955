import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "../../../utilities/hooks";
import { formatMoney } from "../../../utilities/helperFunctions";
import {
  Button,
  Input,
  Form,
  Select,
  InputNumber,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import {
  createPayoutQuotationAction,
  deletePayoutQuotationAction,
} from "../../../services/actions";
// import { ReactComponent as RotateIcon } from "../../../assets/images/rotate.svg";
import { ReactComponent as FadingElipses } from "../../../assets/images/fading-elipse.svg";
import exchangeLogo from "../../../assets/images/exch.svg";
import BankFormContainer from "./BankForm";
import * as ctzc from "country-tz-currency";
import styles from "./payout.module.scss";

export default function Second({ goBack, onClick }) {
  const { wallets } = useSelector((state) => state.walletsReducer);

  const { payoutQuotation, loadingQuotation: loadingPayQuotation } =
    useSelector((state) => state.transactionsReducer);
  const { accountSummary } = useSelector((state) => state.analyticsReducer);

  // console.log(payoutQuotation, "test");

  // eslint-disable-next-line no-unused-vars
  const [walletArray, setWalletArray] = useState(wallets);
  const dispatch = useDispatch();
  const [views, setViews] = useState(false);
  const [formFields, setFormFields] = useState(true);
  const [bankType, setBankType] = useState("");
  const [receipientDetails, setReceipientDetails] = useState();
  const [exchangeFields, setExchangeFields] = useState({
    from: accountSummary?.currency,
    amount: 600,
  });
  // const [disableButton, setDisableButton] = useState(false);
  // const [baseAmount, setBaseAmount] = useState(100);
  let filterTimeout;
  let filterTimeoutTwo;
  const limit = 700;

  const [toAmount, setToAmount] = useState(
    wallets[1]?.currency ? wallets[1].currency : "USD"
  );
  const [inputAmount, setInputAmount] = useState(0);

  const { Option } = Select;

  function onValueChange(value, name) {
    // setBaseAmount(value);
    setExchangeFields({
      ...exchangeFields,
      [name]: value,
    });
    // const newBase = value * payoutQuotation?.quotation?.exchangeRate;
    // setInputAmount(newBase);
    if (name === "from") {
      handleChangeInput(value);
    }
  }

  function handleChangeInput(e) {
    const from = e;
    // console.log(e, "test");
    const { amount } = exchangeFields;

    const data = {
      sourceWallet: from,
      amount: amount.toString(),
      type: bankType,
      destinationCountry: receipientDetails?.country,
      destinationCurrency: toAmount,
      amountType: "SOURCE",
    };
    dispatch(createPayoutQuotationAction(data));
  }

  const updateQueryOne = (value) => {
    // A search query api call.
    clearTimeout(filterTimeout);
    if (!value)
      return setExchangeFields({
        ...exchangeFields,
        amount: 0,
      });
    const { from } = exchangeFields;
    // const walletDetails = walletArray.filter((wallet) => {
    //   return wallet.currency === exchangeFields.from;
    // });
    // filterTimeout = setTimeout(() => {
    //   if (value > (walletDetails[0]?.amount)) {
    //     setExchangeFields({
    //       ...exchangeFields,
    //       amount: value,
    //     });
    //     return message.error(
    //       "Please enter amount less than your wallet balance"
    //     );
    //   } else {
    //     setExchangeFields({
    //       ...exchangeFields,
    //       amount: value,
    //     });
    //     const data = {
    //       sourceWallet: from,
    //       amount: value.toString(),
    //       type: bankType,
    //       destinationCountry: receipientDetails?.country,
    //       destinationCurrency: toAmount,
    //       amountType: "SOURCE",
    //     };
    //     dispatch(createPayoutQuotationAction(data));
    //   }
    // }, limit);
    filterTimeout = setTimeout(() => {
      // console.log("=====> source", value);
      if (value) {
        setExchangeFields({
          ...exchangeFields,
          amount: value,
        });
        const data = {
          sourceWallet: from,
          amount: value.toString(),
          type: bankType,
          destinationCountry: receipientDetails?.country,
          destinationCurrency: toAmount,
          amountType: "SOURCE",
        };
        dispatch(createPayoutQuotationAction(data));
      }
    }, limit);
  };

  function firstCall(value) {
    const { from, amount } = exchangeFields;
    const data = {
      sourceWallet: from,
      amount: amount.toString(),
      type: bankType,
      destinationCountry: receipientDetails?.country,
      destinationCurrency: value,
      amountType: "SOURCE",
    };
    dispatch(createPayoutQuotationAction(data));
  }

  const updateQuery = (value) => {
    clearTimeout(filterTimeoutTwo);
    if (!value) return setInputAmount(0);
    const { from } = exchangeFields;
    const walletDetails = walletArray.filter((wallet) => {
      return wallet.currency === exchangeFields.from;
    });
    filterTimeoutTwo = setTimeout(() => {
      // console.log("=====> destination", value);
      if (
        value / payoutQuotation.quotation.exchangeRate >
        walletDetails[0]?.amount
      ) {
        return message.error(
          "Please enter amount less than your wallet balance"
        );
      } else {
        setInputAmount(value);
        const data = {
          sourceWallet: from,
          amount: value.toString(),
          type: bankType,
          destinationCountry: receipientDetails?.country,
          destinationCurrency: toAmount,
          amountType: "DESTINATION",
        };
        dispatch(createPayoutQuotationAction(data));
      }
    }, limit);
  };

  useEffect(() => {
    if (views === true) {
      firstCall(toAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views, dispatch, toAmount]);

  useEffect(() => {
    if (payoutQuotation?.quotation) {
      const { sourceAmount, destinationAmount } = payoutQuotation?.quotation;
      setExchangeFields({
        ...exchangeFields,
        amount: sourceAmount,
      });
      setInputAmount(destinationAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutQuotation?.quotation]);

  const walletAmount = walletArray.filter((wallet) => {
    return wallet.currency === exchangeFields.from;
  });

  const showBankForm = () => {
    // console.log(walletAmount, 'walletAmount')
    if (walletAmount[0].amount < payoutQuotation.quotation.totalAmount) {
      return message.error("Insufficient Amount");
    } else {
      onClick(bankType, receipientDetails, payoutQuotation);
    }
  };

  const showView = (item, method) => {
    // console.log(item)
    setReceipientDetails(item);
    setBankType(method);
    setToAmount(ctzc.getCountryByCode(item.country).currencyCode);
    setFormFields(false);
    setViews(true);
  };

  const backToView = () => {
    setFormFields(true);
    dispatch(deletePayoutQuotationAction());
    setViews(false);
  };

  return (
    <div className={`paySomeone ${styles.recieverWrapper}`}>
      {views === true ? (
        <div className="exchange-select">
          <p>How much are you sending</p>
          <Form>
            <Input.Group compact style={{ width: "100%" }}>
              <Select
                style={{ width: "30%" }}
                name="from"
                onChange={(value) => onValueChange(value, "from")}
                // disabled={loadingWallets}
                value={exchangeFields.from}
              >
                {wallets
                  .filter((data) => data.enabled && data.isCrypto === false)
                  .map((wallet) => {
                    return (
                      <Option key={wallet.currency} value={wallet.currency}>
                        <img src={wallet.icon} alt="flag" />
                        {wallet.currency}
                      </Option>
                    );
                  })}
              </Select>
              <InputNumber
                style={{ width: "70%" }}
                defaultValue="100.00"
                value={exchangeFields.amount}
                min="0"
                // max={walletAmount[0]?.amount}
                precision="2"
                name="amount"
                onChange={(value) => updateQueryOne(value)}
                // onBlur={handleChanges}
              />
            </Input.Group>
            <br />
            {payoutQuotation?.quotation?.sourceCurrency ? (
              <p className="text-black-50">
                {exchangeFields.from} balance:&nbsp;
                {formatMoney(walletAmount[0]?.amount.toFixed(2))}
              </p>
            ) : null}

            <hr />

            <Row type="flex" align="middle" className="mt-4">
              <Col style={{ marginRight: "40px" }}>
                {/* <RotateIcon width="48px" /> */}
                <img src={exchangeLogo} alt="" />
              </Col>
              <Col style={{ marginRight: "20px" }}>
                <FadingElipses />
              </Col>
              <Col>
                <div>
                  <span>Exchange Rate </span>
                </div>
                <div className="mt-2">
                  {payoutQuotation?.quotation?.sourceCurrency ? (
                    <h6>
                      <b>
                        {payoutQuotation.quotation.sourceCurrency}&nbsp;1
                        &nbsp;&nbsp; =&nbsp;&nbsp;
                        {payoutQuotation.quotation.exchangeRate}&nbsp;
                        {payoutQuotation.quotation.destinationCurrency}
                      </b>
                    </h6>
                  ) : null}
                </div>
                <span>
                  Fees:
                  <>
                    {payoutQuotation?.quotation?.totalFees ? (
                      <>
                        {payoutQuotation.quotation.sourceCurrency}&nbsp;
                        {formatMoney(payoutQuotation.quotation.totalFees)}
                      </>
                    ) : null}
                  </>
                </span>
              </Col>
            </Row>

            <div className="pt-4">
              <p className="">Receiver would get</p>
            </div>

            {loadingPayQuotation ? (
              <Spin tip="Loading...">
                <Input.Group compact>
                  <Select
                    style={{ width: "30%" }}
                    name="toAmount"
                    defaultValue={toAmount}
                    disabled
                  >
                    {wallets
                      .filter((data) => data.enabled)
                      .map((wallet) => {
                        return (
                          <Option key={wallet.currency} value={wallet.currency}>
                            <img src={wallet.icon} alt="flag" />
                            {wallet.currency}
                          </Option>
                        );
                      })}
                  </Select>

                  <InputNumber
                    value={inputAmount}
                    style={{ width: "70%" }}
                    min="1"
                    precision="2"
                    // onBlur={handleChangesTwo}
                    onChange={(value) => updateQuery(value)}
                  />
                </Input.Group>
              </Spin>
            ) : (
              <>
                <Input.Group compact>
                  <Select
                    style={{ width: "30%" }}
                    name="toAmount"
                    defaultValue={toAmount}
                    disabled
                  >
                    {wallets
                      .filter((data) => data.enabled)
                      .map((wallet) => {
                        return (
                          <Option key={wallet.currency} value={wallet.currency}>
                            <img src={wallet.icon} alt="flag" />
                            {wallet.currency}
                          </Option>
                        );
                      })}
                  </Select>

                  <InputNumber
                    value={inputAmount}
                    style={{ width: "70%" }}
                    min="1"
                    precision="2"
                    // onBlur={handleChangesTwo}
                    onChange={(value) => updateQuery(value)}
                  />
                </Input.Group>
              </>
            )}

            <br />
            <Form.Item>
              <Button
                size="large"
                type="default"
                className="grey-btn"
                onClick={backToView}
                style={{ width: "49%" }}
              >
                Go Back
              </Button>
              <Button
                size="large"
                type="default"
                className="pay-blue-btn"
                onClick={showBankForm}
                disabled={
                  loadingPayQuotation ||
                  walletAmount[0]?.amount <
                    payoutQuotation?.quotation?.totalAmount
                }
                style={{ width: "49%", marginLeft: "5px" }}
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : null}

      {formFields && (
        <BankFormContainer handleClick={showView} goBack={goBack} />
      )}
    </div>
  );
}
