import { useEffect, useState } from "react";
import { Button, Spin } from "antd";

import { useSelector, useDispatch } from "../../../utilities/hooks";
import { submitQuotation } from "../../../services/actions";
import {
  formatMoney,
  formatBalanceCrypto,
} from "../../../utilities/helperFunctions";
import Countdown from "react-countdown";

export default function SummaryPage({
  onBackPress,
  cryptoCheck,
  difference,
  isShown,
  onIsShown,
  onComplete,
}) {
  const {
    quotation,
    loading: loadingQuotation,
    submitLoading,
  } = useSelector((state) => state.quotationsReducer);

  const [defaultNumber, setDefaultNumber] = useState(
    difference.endTime - Date.now()
  );

  const [countdownApi, setCountDownApi] = useState(null);

  const dispatch = useDispatch();

  function setRef(countdown) {
    if (countdown) {
      setCountDownApi(countdown.getApi());
    }
  }

  const renderer = ({ seconds }) => <b> Confirm and exchange ({seconds})</b>;

  const handleSubmitQuotation = () => {
    handlePauseClick();
    dispatch(submitQuotation({ token: quotation.token }));
  };

  function handlePause(value) {
    setDefaultNumber(value.total);
  }

  function handlePauseClick() {
    countdownApi && countdownApi.pause();
  }

  useEffect(() => {
    if (!isShown) {
      setDefaultNumber(30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotation?.token]);

  function handleComplete() {
    onIsShown(false);
    onComplete();
  }

  return (
    <div>
      <div className="mt-4 mb-4">
        <h2 className="text-bold color-bold text-md">Exchange money</h2>
      </div>

      <div className="mt-4 mb-4">
        <p>Transaction summary</p>
        <div>
          <b>Exchange</b>
          <br />
          <br />
          <div className="flex justify-content-between">
            <p>Exchange amount</p>
            <b>
              {quotation.baseCurrency}
              {cryptoCheck(quotation.baseCurrency).isCrypto
                ? formatBalanceCrypto(quotation.baseAmount)
                : formatMoney(quotation.baseAmount)}
            </b>
          </div>
          <div className="flex justify-content-between">
            <p>{quotation.baseCurrency} balance after exchange</p>
            <b>
              {quotation.baseCurrency}
              {cryptoCheck(quotation.baseCurrency).isCrypto
                ? formatBalanceCrypto(quotation.baseWalletAfter)
                : formatMoney(quotation.baseWalletAfter.toFixed(2))}
            </b>
          </div>
          <b>Rate</b>
          <br />
          <br />
          <div className="flex justify-content-between">
            <p>Exchange Rate</p>
            <b>{quotation.rate}</b>
          </div>

          <b>Receive</b>
          <br />
          <br />

          <div className="flex justify-content-between">
            <p>You will recieve</p>
            <b>
              {quotation.destCurrency}&nbsp;
              {cryptoCheck(quotation.destCurrency).isCrypto
                ? formatBalanceCrypto(quotation.destAmount)
                : formatMoney(quotation.destAmount)}
            </b>
          </div>
          <div className="flex justify-content-between">
            <p>{quotation.destCurrency} balance after exchange</p>
            <b>
              {quotation.destCurrency}&nbsp;
              {cryptoCheck(quotation.destCurrency).isCrypto
                ? formatBalanceCrypto(quotation.destWalletAfter)
                : formatMoney(quotation.destWalletAfter.toFixed(2))}
            </b>
          </div>
        </div>

        {submitLoading ? (
          <Spin tip="Loading...">
            <hr />
          </Spin>
        ) : (
          <hr />
        )}
        <br />
        <div className="row">
          <Button
            size="small"
            type="default"
            className="grey-btn"
            onClick={onBackPress}
            style={{ width: "49%" }}
            disabled={loadingQuotation}
          >
            <b>Cancel and go back </b>
          </Button>
          <Button
            size="small"
            type="default"
            className="pay-blue-btn"
            onClick={handleSubmitQuotation}
            style={{ width: "49%", marginLeft: "5px" }}
            disabled={!quotation || loadingQuotation || !quotation.token}
          >
            {!quotation || loadingQuotation || !quotation.token || !isShown ? (
              <b>Confirm and exchange</b>
            ) : (
              <Countdown
                date={Date.now() + defaultNumber}
                renderer={renderer}
                onComplete={handleComplete}
                onPause={(value) => handlePause(value)}
                ref={(countdown) => setRef(countdown)}
              />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
