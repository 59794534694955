import React from "react";
import logo from "../../../assets/images/v-card-logo.svg";
import { useFormikContext } from "formik";

export default function Second({ card, next, colors, cardDetails }) {
  const {
    values: { selectedBackground, selectedColor },
    setFieldValue,
  } = useFormikContext();

  const handleBackground = (data) => {
    setFieldValue("selectedBackground", colors[data]);
    setFieldValue("selectedColor", data);
    cardDetails({ colors: data });
  };

  return (
    <div className="banktransfer-others">
      <div>
        <div
          className="virtual-card-design p-4"
          style={{ background: selectedBackground }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <img src={logo} alt="logo" className="card-logo" width={30} />
            <h2>VIRTUAL</h2>
          </div>
          <br />
          <br />
          <br />
          <br />
          <h4 className="text-center">{card.title}</h4>
        </div>

        <br />
        <br />
        <p className="wallet-addmoney-title mb-2">Choose a color</p>
        <div className="colors-container">
          <button
            onClick={() => handleBackground("blue")}
            className="color blue"
            style={{
              outline: `2px solid ${
                selectedColor === "blue" ? selectedBackground : "white"
              }`,
            }}
          ></button>
          <button
            onClick={() => handleBackground("black")}
            className="color black"
            style={{
              outline: `2px solid ${
                selectedColor === "black" ? selectedBackground : "white"
              }`,
            }}
          ></button>
          <button
            onClick={() => handleBackground("purple")}
            className="color purple"
            style={{
              outline: `2px solid ${
                selectedColor === "purple" ? selectedBackground : "white"
              }`,
            }}
          ></button>
          <button
            onClick={() => handleBackground("orange")}
            className="color orange"
            style={{
              outline: `2px solid ${
                selectedColor === "orange" ? selectedBackground : "white"
              }`,
            }}
          ></button>
          <button
            onClick={() => handleBackground("yellow")}
            className="color yellow"
            style={{
              outline: `2px solid ${
                selectedColor === "yellow" ? selectedBackground : "white"
              }`,
            }}
          ></button>
        </div>
        <br />
        <br />
      </div>

      <button
        className="btn-btn-btn"
        style={{ cursor: "pointer" }}
        disabled={!selectedColor}
        onClick={next}
      >
        Continue
      </button>
    </div>
  );
}
