import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "../../utilities/hooks";
import accessIcon from "../../assets/images/noAccess.svg";
import dots from "../../assets/images/threedots.svg";
import { useIntercom } from "react-use-intercom";
import {
  fetchWalletsAction,
  fetchCardsAction,
  fetchCardAnalyticsAction,
  toggleCreateCardModalAction,
} from "../../services/actions";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Tag, Spin, message, Typography, Checkbox } from "antd";
import GeneralTable from "../../components/GeneralTable";
import InfoIcon from "../../assets/images/Information.svg";
import { ReactComponent as EmptyState } from "../../assets/images/emptystate-cards.svg";
import { ReactComponent as FreezeIcon } from "../../assets/images/freeze-card.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete-card.svg";
import CardDetails from "../../components/modals/CardDetails";
import DeleteCard from "../../components/modals/deleteCard";
import makeAPICall from "../../utilities/apiUtils";
import { formatBalance, maskCardNumber } from "../../utilities/helperFunctions";
import { AccessControl } from "accesscontrol";
import { useHistory } from "react-router-dom";

import styles from "./Cards.module.scss";

const { Paragraph } = Typography;

export default function CardsContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { show, boot } = useIntercom();
  const { roles } = useSelector((state) => state.RoleReducer);
  const { user: userInfo } = useSelector((state) => state.profileReducer);
  const { cards, loading, cardAnalytics, loadingCard, controller } =
    useSelector((state) => state.cardsReducer);
  const [hasKyc, setHasKyc] = useState(true);
  const [cardDetailsModal, setCardDetailsModal] = useState(false);
  const [cardDetails, setCardDetails] = useState();
  const [isLoadingSingle, setLoadingStateSingle] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [cardId, setCardId] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [currentSelectedPeriod, setCurrentSelectedPeriod] =
    useState("This month");

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(userInfo?.role).createAny(value);
    return permission;
  }

  const now = moment().format("YYYY-MM-DD");
  const dateLimits = {
    today: { from: moment().startOf("day").format("YYYY-MM-DD"), to: now },
    week: {
      from: moment().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    month: {
      from: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    year: {
      from: moment().subtract(1, "year").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
  };

  const filterOptions = [
    { label: "Status", clickable: true },
    { label: "Date Created", clickable: true },
  ];
  // const filterOptions = [];
  const filterSelectOptions = {
    Status: ["Select card status", "Active", "Frozen", "Deleting", "Deleted"],
    "Date Created": [
      "Select date",
      "Today",
      "This week",
      "This month",
      "Past year",
      "Custom",
    ],
  };

  boot({
    name: userInfo?.account?.name,
    email: userInfo?.account?.email,
    user_id: userInfo?.account?.id,
    isB2B: "B2B",
  });

  useEffect(() => {
    if (userInfo.account && userInfo.account.isVerified === false) {
      setHasKyc(false);
    } else {
      dispatch(fetchWalletsAction());
      dispatch(fetchCardsAction({}));
      dispatch(fetchCardAnalyticsAction({}));
      setHasKyc(true);
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [controller]);

  const userMenu = (
    <Menu className="header-dropdown-user-container-action-card">
      <Menu.Item
        key="1"
        onClick={() => filterAnalytics(dateLimits.today, "Today")}
      >
        <p>Today</p>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => filterAnalytics(dateLimits.week, "This week")}
      >
        <p>This week</p>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => filterAnalytics(dateLimits.month, "This month")}
      >
        <p>This month</p>
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => filterAnalytics(dateLimits.year, "This year")}
      >
        <p>Past year</p>
      </Menu.Item>
    </Menu>
  );

  function handleCreateCard() {
    dispatch(toggleCreateCardModalAction());
  }

  function onCloseModal() {
    setCardDetailsModal(false);
  }

  function onOpenModal(row) {
    setLoadingStateSingle(true);
    return makeAPICall({
      path: `cards/${row.id}`,
      method: "GET",
    })
      .then((res) => {
        // console.log(res);
        setLoadingStateSingle(false);
        setCardDetails(res.data);
        setCardDetailsModal(true);
      })
      .catch((err) => {
        setLoadingStateSingle(false);
        console.log(err);
      });
  }

  const tableAction = (row) => (
    <Menu className="header-dropdown-user-container-action-card">
      {row.status === "terminating" || row.status === "terminated"
        ? null
        : checkAccess("cards").granted && (
            <Menu.Item
              key="1"
              className="d-flex align-items-center"
              onClick={() => onFreeze(row)}
            >
              <FreezeIcon className="me-2" />
              <p>
                {row?.status === "frozen" ? "Unfreeze card" : "Freeze card"}
              </p>
            </Menu.Item>
          )}

      <Menu.Item
        key="2"
        className="d-flex align-items-center"
        onClick={() => onOpenModal(row)}
      >
        <img
          src={InfoIcon}
          alt="withdraw"
          width="16px"
          height="16px"
          className="me-2"
        />
        <p>Card details</p>
      </Menu.Item>
      {/* <Menu.Divider /> */}
      {row.status === "terminating" || row.status === "terminated"
        ? null
        : checkAccess("cards").granted && (
            <Menu.Item
              key="3"
              className="d-flex align-items-center"
              onClick={() => onDelete(row)}
            >
              <DeleteIcon className="me-2" />
              <p>Delete card</p>
            </Menu.Item>
          )}
    </Menu>
  );

  const columns = [
    {
      title: "Card title",
      dataIndex: "title",
      key: "title",
      width: 200,
      render: (title, row) => (
        <div onClick={() => onOpenModal(row)} style={{ cursor: "pointer" }}>
          {title}
        </div>
      ),
    },
    {
      title: "Card ID",
      dataIndex: "id",
      key: "id",
      width: 200,
      render: (id, row) => (
        <Paragraph
          copyable={{
            text: id,
          }}
        >
          {id.substring(0, 9)}...
        </Paragraph>
      ),
    },

    {
      title: "Card info",
      dataIndex: "mask",
      key: "mask",
      width: 200,
      render: (mask, row) => (
        <div onClick={() => onOpenModal(row)} style={{ cursor: "pointer" }}>
          {maskCardNumber(mask)}
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (name, row) => (
        <div onClick={() => onOpenModal(row)} style={{ cursor: "pointer" }}>
          {name ? name : "No username"}
        </div>
      ),
    },
    {
      title: "Date created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (createdAt, row) => (
        <span onClick={() => onOpenModal(row)} style={{ cursor: "pointer" }}>
          {moment(createdAt).format("MMM DD YYYY")}
        </span>
      ),
    },
    {
      title: "Subscription",
      dataIndex: "isNonSubscription",
      key: "isNonSubscription",
      width: 150,
      render: (isNonSubscription, row) => (
        <div>
          <Checkbox checked={!isNonSubscription} disabled />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status, row) => {
        if (status === "active") {
          return (
            <Tag
              color="#E2FCCC"
              style={{
                color: "#0F730E",
                border: "1px solid #99E558",
                cursor: "pointer",
              }}
              onClick={() => onOpenModal(row)}
            >
              Active
            </Tag>
          );
        } else if (status === "frozen") {
          return (
            <Tag
              color="#EEEEFD"
              style={{
                color: "#3127F1",
                border: "1px solid #7D77F6",
                cursor: "pointer",
              }}
              onClick={() => onOpenModal(row)}
            >
              Frozen
            </Tag>
          );
        } else if (status === "terminating") {
          return (
            <Tag
              color="#FDEAEE"
              style={{
                color: "#A30010",
                border: "1px solid #F25E6C",
                cursor: "pointer",
              }}
              onClick={() => onOpenModal(row)}
            >
              Deleting
            </Tag>
          );
        } else {
          return (
            <Tag
              color="#FDEAEE"
              style={{
                color: "#A30010",
                border: "1px solid #F25E6C",
                cursor: "pointer",
              }}
              onClick={() => onOpenModal(row)}
            >
              Deleted
            </Tag>
          );
        }
      },
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Dropdown
          overlay={tableAction(row)}
          trigger={["click"]}
          placement="bottomRight"
        >
          <img src={dots} alt="drop down" style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const handleOkDelete = () => {
    const data = {
      cardId: cardId,
    };
    setLoadingDelete(true);
    return makeAPICall({
      path: "cards/terminate",
      method: "POST",
      payload: data,
    })
      .then((res) => {
        // console.log(res);
        message.success("Your card has been deleted successfully", 5);
        setLoadingDelete(false);
        setCardId("");
        dispatch(fetchCardsAction({}));
        dispatch(fetchCardAnalyticsAction({}));
        dispatch(fetchWalletsAction());
        setIsModalDeleteOpen(false);
      })
      .catch((err) => {
        setLoadingDelete(false);
        message.error(err.message, 5);
      });
  };
  const onDelete = (row) => {
    setCardId(row.id);
    setIsModalDeleteOpen(true);
  };

  function handleCancelDelete() {
    setIsModalDeleteOpen(false);
  }

  function onFreeze(row) {
    if (row.status === "frozen") {
      const data = {
        cardId: row.id,
      };
      setLoadingStateSingle(true);
      return makeAPICall({
        path: "cards/unfreeze",
        method: "POST",
        payload: data,
      })
        .then((res) => {
          // console.log(res);
          message.success(`Your card has been unfrozen`, 5);
          setLoadingStateSingle(false);
          dispatch(fetchCardsAction({}));
          dispatch(fetchCardAnalyticsAction({}));
          dispatch(fetchWalletsAction());
        })
        .catch((err) => {
          setLoadingStateSingle(false);
          message.error(err.message, 5);
        });
    } else {
      const data = {
        cardId: row.id,
      };
      setLoadingStateSingle(true);
      return makeAPICall({
        path: "cards/freeze",
        method: "POST",
        payload: data,
      })
        .then((res) => {
          // console.log(res);
          message.success(`Your card has been frozen`, 5);
          setLoadingStateSingle(false);
          dispatch(fetchCardsAction({}));
          dispatch(fetchCardAnalyticsAction({}));
          dispatch(fetchWalletsAction());
        })
        .catch((err) => {
          setLoadingStateSingle(false);
          message.error(err.message, 5);
        });
    }
  }

  const filterTransactions = (value, custom) => {
    if (
      (value === "Today" ||
        value === "This week" ||
        value === "This month" ||
        value === "Past year") &&
      !custom
    ) {
      dispatch(
        fetchCardsAction({
          params: {
            from: dateLimits[
              value === "Today"
                ? "today"
                : value === "This week"
                ? "week"
                : value === "This month"
                ? "month"
                : value === "Past year"
                ? "year"
                : "today"
            ].from,
            to: dateLimits[
              value === "Today"
                ? "today"
                : value === "This week"
                ? "week"
                : value === "This month"
                ? "month"
                : value === "Past year"
                ? "year"
                : "today"
            ].to,
          },
        })
      );
    } else if (value === "Custom") {
      dispatch(
        fetchCardsAction({
          params: {
            from: custom.from,
            to: custom.to,
          },
        })
      );
      // console.log(value, custom, 'text');
    } else if (value === "cleared") {
      dispatch(fetchCardsAction({}));
    } else {
      dispatch(
        fetchCardsAction({
          params: {
            status:
              value === "Active"
                ? "active"
                : value === "Frozen"
                ? "frozen"
                : value === "Deleted"
                ? "terminated"
                : value === "Deleting"
                ? "terminating"
                : null,
          },
        })
      );
    }
  };

  const handleInputChange = (value) => {
    dispatch(
      fetchCardsAction({
        params: {
          query: value,
        },
      })
    );
  };

  const paginateAll = (page, pageSize) => {
    const params = {
      limit: pageSize,
      page: page,
    };
    dispatch(fetchCardsAction({ params }));
  };

  function filterAnalytics(value, item) {
    // console.log(value);
    setCurrentSelectedPeriod(item);
    dispatch(
      fetchCardAnalyticsAction({
        params: {
          from: value.from,
          to: value.to,
        },
      })
    );
  }

  const goToCardUsers = () => {
    history.push("/cards-users");
  };

  const isAnalyticsEmpty =
    !cardAnalytics ||
    cardAnalytics?.cardsCreated === 0 ||
    Object.keys(cardAnalytics).length === 0;
  const isArrayEmpty = !cards?.cards || cards?.cards.length === 0;

  return (
    <div className={styles.container}>
      <div>
        <h1 className="text-md color-bold text-bold mt-2">Cards</h1>
      </div>
      <Spin spinning={loading || loadingCard || isLoadingSingle}>
        {hasKyc ? (
          <div>
            <div>
              {isAnalyticsEmpty && isArrayEmpty && !loading ? (
                <div className="completed-kyc-state">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <EmptyState />
                      <h1>Power Up Your Business with Virtual Cards</h1>
                      <p>
                        Create virtual cards for your companies financial
                        efficiency
                      </p>
                      {checkAccess("cards").granted ? (
                        <div>
                          <button
                            type="button"
                            className="general-btn me-2"
                            onClick={goToCardUsers}
                          >
                            Manage card users
                          </button>
                          <button
                            type="button"
                            className="general-btn"
                            onClick={handleCreateCard}
                          >
                            Create card
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div className="cards-sub-title">
                      Displaying card information for &nbsp;
                      <Dropdown
                        overlay={userMenu}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <span>
                          {currentSelectedPeriod} <DownOutlined />
                        </span>
                      </Dropdown>
                    </div>

                    <div className={styles.buttonContainer}>
                      <button
                        type="button"
                        className="general-btn me-2"
                        onClick={goToCardUsers}
                      >
                        Manage card users
                      </button>
                      {checkAccess("cards").granted && (
                        <button
                          type="button"
                          className="general-btn"
                          onClick={handleCreateCard}
                        >
                          Create card
                        </button>
                      )}
                    </div>
                  </div>
                  <br />

                  <div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card-wallet-card">
                          <h1>Cards created</h1>
                          <p>{cardAnalytics?.cardsCreated}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-wallet-card">
                          <h1>Total card top ups</h1>
                          <p>
                            USD {formatBalance(cardAnalytics?.totalCardTopUp)}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-wallet-card">
                          <h1>Total card expenses</h1>
                          <p>
                            USD {formatBalance(cardAnalytics?.totalCardSpend)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card-wallet-card">
                          <h1>Active cards</h1>
                          <p>{cardAnalytics?.cardsActive}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-wallet-card">
                          <h1>Frozen cards</h1>
                          <p>{cardAnalytics?.frozenCards}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-wallet-card">
                          <h1>Deleted cards</h1>
                          <p>{cardAnalytics?.terminatedCards}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-table-h1 mt-4 ">
                    <h1>Card information</h1>

                    <GeneralTable
                      columns={columns}
                      placeHolder="Search card by title"
                      filter={filterOptions}
                      filterSelect={filterSelectOptions}
                      download={false}
                      filterTransactions={filterTransactions}
                      handleInputChange={handleInputChange}
                      pagination={cards}
                      paginateAll={paginateAll}
                      data={cards?.cards}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="completed-kyc-state">
            <div className="row">
              <div className="col-md-12 text-center">
                <img src={accessIcon} alt="" />
                <h1>You do not have access</h1>
                <p>
                  Please complete your KYB verification, to be able to view
                  contents of this page, click on the “complete KYB” button
                </p>
                <button onClick={show} style={{ cursor: "pointer" }}>
                  Complete KYB
                </button>
              </div>
            </div>
          </div>
        )}
        {cardDetailsModal && (
          <CardDetails
            cardDetailsAll={cardDetails}
            tabIndexNow={0}
            onClose={onCloseModal}
            show={cardDetailsModal}
          />
        )}
        {isModalDeleteOpen && (
          <DeleteCard
            isModalOpen={isModalDeleteOpen}
            handleCancel={handleCancelDelete}
            handleOk={handleOkDelete}
            loading={loadingDelete}
          />
        )}
      </Spin>
    </div>
  );
}
