import React from "react";
import { Layout, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import useMobile from "../../hooks/useMobile";
import { Steps } from "../Steps";

import styles from "./StepsWrapper.module.scss";

const { Header } = Layout;

const StepsWrapper = ({
  currentStep,
  children,
  steps = [],
  onChange,
  title,
  onGoBack,
  hideBackButton,
  contentClassName,
}) => {
  const isMobile = useMobile();
  const showBackButton = currentStep !== 0 && !hideBackButton;

  const showHeader = !!title || showBackButton;
  return (
    <div className={styles.wrapper}>
      <div className={styles.aside} width="auto">
        {showHeader && (
          <Header className={styles.header}>
            {title && (
              <h2 level={1} className={styles.title}>
                {title}
              </h2>
            )}

            {showBackButton && (
              <Button
                type="link"
                icon={<ArrowLeftOutlined style={{ color: "#444052" }} />}
                className={styles.back}
                onClick={onGoBack}
              >
                Back
              </Button>
            )}
          </Header>
        )}

        <Steps
          direction={isMobile ? "horizontal" : "vertical"}
          currentStep={currentStep}
          className={styles.steps}
          size="small"
          onStepClick={onChange}
          steps={steps}
          key="steps"
          hideBackButton={hideBackButton}
        />
      </div>

      <main className={`${styles.content} ${contentClassName}`}>
        {children}
      </main>
    </div>
  );
};

StepsWrapper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  steps: PropTypes.array,
  onChange: PropTypes.func,
  title: PropTypes.string,
  onGoBack: PropTypes.func,
  hideBackButton: PropTypes.bool,
  contentClassName: PropTypes.string,
};

export default StepsWrapper;
