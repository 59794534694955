import { useEffect, useState } from "react";

import { Button, message, Spin } from "antd";
import { formatBalance } from "../../../../utilities/helperFunctions";
import { useSelector, useDispatch } from "../../../../utilities/hooks";
import {
  createPayoutAction,
  createPayoutQuotationAction,
} from "../../../../services/actions";
import Countdown from "react-countdown";

import styles from "./beneficiary.module.scss";
import { useFormikContext } from "formik";

export default function Third({
  onClick,
  currencyDetails,
  paymentType,
  payOutData,
  difference,
}) {
  const dispatch = useDispatch();
  const {
    values: { reason, selectedBeneficiary: profile },
  } = useFormikContext();

  const { payout, loadingPayout } = useSelector(
    (state) => state.transactionsReducer
  );

  const { payoutQuotation, loadingQuotation: loadingPayQuotation } =
    useSelector((state) => state.transactionsReducer);

  const { wallets } = useSelector((state) => state.walletsReducer);

  const [usedNumber, setUsedNumber] = useState(difference.endTime - Date.now());
  const [isShown, setIsShown] = useState(true);
  const [countdownApi, setCountDownApi] = useState(null);

  const renderer = ({ seconds }) => <b> Confirm and Send ({seconds})</b>;

  const createPayout = () => {
    handlePauseClick();
    const data = {
      beneficiaryId: profile.id,
      token: payoutQuotation.token,
    };
    dispatch(createPayoutAction(data));
  };

  useEffect(() => {
    if (!isShown) {
      setUsedNumber(30000);
      setIsShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutQuotation?.quotation]);

  useEffect(() => {
    if (payout) {
      message.success("Payout request created successfully");
      onClick(payout);
    }
  }, [payout, onClick]);

  const walletAmount = wallets.filter((wallet) => {
    return wallet.currency === currencyDetails?.quotation?.sourceCurrency;
  });

  const handleComplete = (e) => {
    setIsShown(false);
    dispatch(createPayoutQuotationAction(payOutData));
  };

  function handlePause(value) {
    setUsedNumber(value.total);
  }

  function handlePauseClick() {
    countdownApi && countdownApi.pause();
  }

  function setRef(countdown) {
    if (countdown) {
      setCountDownApi(countdown.getApi());
    }
  }

  return (
    <div>
      <h1 className="text-md color-bold text-bold mt-2">
        Review your payout details
      </h1>
      <br />
      <div className="d-flex">
        <p className="pay-summary-h1">Payout details</p>
        <p className="ms-auto">&nbsp;</p>
      </div>
      <br />
      <div className="d-flex">
        <p>You are sending</p>
        <p className="ms-auto">
          {currencyDetails.quotation.sourceCurrency}&nbsp;
          {formatBalance(currencyDetails.quotation.sourceAmount)}
        </p>
      </div>
      <div className="d-flex">
        <p>Total fees</p>
        <p className="ms-auto">
          {currencyDetails.quotation.sourceCurrency}&nbsp;
          {currencyDetails.quotation.totalFees}
        </p>
      </div>
      <div className="d-flex">
        <p>Amount we will convert</p>
        <p className="ms-auto">
          {currencyDetails.quotation.sourceCurrency}&nbsp;
          {formatBalance(currencyDetails.quotation.sourceAmount)}
        </p>
      </div>
      <div className="d-flex">
        <p>
          {profile.firstName}&nbsp; {profile.lastName} Gets
        </p>
        <p className="ms-auto">
          {currencyDetails.quotation.destinationCurrency}&nbsp;
          {formatBalance(currencyDetails.quotation.destinationAmount)}
        </p>
      </div>
      <div className="d-flex">
        <p>Currency balance after payout</p>
        <p className="ms-auto">
          {currencyDetails.quotation.sourceCurrency}&nbsp;
          {formatBalance(
            walletAmount[0].amount - currencyDetails.quotation.totalAmount
          )}
        </p>
      </div>
      <div className="flex-column pt-1">
        <p className="pay-summary-h1">Reason</p>
        <p className="ms-auto">{reason}</p>
      </div>
      <br />
      <div className="d-flex">
        <p className="pay-summary-h1">Receipient details</p>
        <p className="ms-auto">&nbsp;</p>
      </div>
      <div className="d-flex">
        <p>Name</p>
        <p className="ms-auto">
          {profile.firstName}&nbsp; {profile.lastName}
        </p>
      </div>
      <div className="d-flex">
        <p>Send to</p>
        <p className="ms-auto">
          {paymentType.charAt(0).toUpperCase() + paymentType.slice(1)}
        </p>
      </div>
      <div className="d-flex">
        <p>Phone number</p>
        <p className="ms-auto">{profile.phoneNumber}</p>
      </div>
      {paymentType === "bank" ? (
        <>
          <div className="d-flex">
            <p>Bank name</p>
            <p className="ms-auto">
              {profile.firstName}&nbsp; {profile.lastName}
            </p>
          </div>
          <div className="d-flex">
            <p>Account number</p>
            <p className="ms-auto">{profile.bankAccountNumber}</p>
          </div>
        </>
      ) : null}
      <br />
      {/* <div className="d-flex">
              <p>
                <img src={schedule} alt="" width="20px" />
                &nbsp; Schedule
              </p>
              <p className="ms-auto">
                <span>
                  <Switch size="medium" />
                </span>
              </p>
            </div> */}
      {loadingPayQuotation ? (
        <Spin tip="Loading...">
          <hr />
        </Spin>
      ) : (
        <hr />
      )}
      <br />
      <Button
        size="large"
        type="default"
        className={`pay-blue-btn ${styles.button}`}
        onClick={createPayout}
        disabled={!isShown || loadingPayQuotation}
        loading={loadingPayout}
      >
        {loadingPayQuotation || !isShown ? (
          <b>Confirm and Send </b>
        ) : (
          <Countdown
            date={Date.now() + usedNumber}
            renderer={renderer}
            onComplete={handleComplete}
            onPause={(value) => handlePause(value)}
            ref={(countdown) => setRef(countdown)}
          />
        )}
      </Button>
    </div>
  );
}
