import React, { useState, useEffect } from "react";
import { InputNumber } from "antd";
import providus from "../../../assets/images/providus.jpg";
import wema from "../../../assets/images/wema.jpg";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import {
  createAddMoney,
  fetchUserBanksAction,
} from "../../../services/actions";
import Loader from "../../loaders/loading";

export default function Nigeria({ next }) {
  const { bankAccounts, loading } = useSelector(
    (state) => state.UserBanksReducer
  );

  // console.log(bankAccounts, "bankAccounts");

  const { addMoney, loading: addLoading } = useSelector(
    (state) => state.addMoneyReducer
  );

  const dispatch = useDispatch();
  const [showBvn, setShowBvn] = useState(false);
  const [bvn, setBvn] = useState("");
  const [showAccount, setShowAccount] = useState(true);
  const [account, setAccount] = useState(false);

  const addAccount = () => {
    setShowAccount(false);
    setShowBvn(true);
  };

  const showOld = () => {
    setShowAccount(true);
    setShowBvn(false);
  };

  function onChange(value) {
    setBvn(value);
  }

  useEffect(() => {
    if (bankAccounts) {
      setAccount(true);
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (addMoney) {
      // console.log(addMoney, "test");
      dispatch(fetchUserBanksAction());
      setShowAccount(true);
      setShowBvn(false);
    }
  }, [dispatch, addMoney]);

  useEffect(() => {
    dispatch(fetchUserBanksAction());
  }, [dispatch]);

  const createAccount = () => {
    const data = {
      currency: "NGN",
      bvn: bvn,
    };
    dispatch(createAddMoney(data));
  };

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {showAccount ? (
            <div className="banktransfer-others">
              <h2 className="text-center">Bank transfer</h2>
              <p className="text-center">
                Make a tranfer from your local bank or bank app to an account
                below and we would add them to your Evesend wallet.
              </p>
              <br />
              <div>
                {account && (
                  <div>
                    {bankAccounts?.personal.length === 0 ? (
                      <div className="newBankAccount" onClick={addAccount}>
                        Add new account <i className="fa fa-plus"></i>
                      </div>
                    ) : (
                      <div>
                        {bankAccounts.personal.map((banks, index) => (
                          <div
                            className="bank-transfer-modal"
                            style={{ marginBottom: "10px", cursor: "pointer" }}
                            key={index}
                          >
                            <div className="d-flex" onClick={() => next(banks)}>
                              <img
                                src={
                                  banks?.bank === "Wema Bank" ? wema : providus
                                }
                                alt="wema icon"
                                className="align-self-center curImage"
                              />

                              <p className="">
                                <span>{banks?.bank}</span>
                                <br />
                                {banks?.accountNumber}
                              </p>
                              <div className="ms-auto align-self-center">
                                <i className="fa fa-angle-right showNow"></i>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      // <>
                      //   <div className="bank-transfer-modal">
                      //     <div className="d-flex" onClick={next}>
                      //       <img
                      //         src={wema}
                      //         alt="wema icon"
                      //         className="align-self-center curImage"
                      //       />

                      //       <p className="">
                      //         <span>Wema bank</span>
                      //         <br />
                      //         7810190675
                      //       </p>
                      //       <div className="ms-auto align-self-center">
                      //         <i className="fa fa-angle-right showNow"></i>
                      //       </div>
                      //     </div>
                      //   </div>
                      //   <br />
                      //   <div className="newBankAccount" onClick={addAccount}>
                      //     Add new account <i className="fa fa-plus"></i>
                      //   </div>
                      // </>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}

      <>
        {addLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {showBvn ? (
              <div className="banktransfer-others">
                <h2 className="text-center">Add BVN</h2>
                <p className="text-center">
                  To create a new account we would need your BVN
                </p>
                <br />
                <InputNumber
                  placeholder="Enter 11 digit BVN"
                  onChange={onChange}
                  style={{ width: "100%" }}
                />
                <br />
                <br />
                <div className="text-center">
                  <button
                    className="btn-btn-btn"
                    disabled={!bvn}
                    onClick={createAccount}
                  >
                    Submit BVN
                  </button>
                </div>
                <br />
                <div className="text-center">
                  <button className="btn-btn-playnothing" onClick={showOld}>
                    Cancel and go back
                  </button>
                </div>
              </div>
            ) : null}
          </>
        )}
      </>
    </>
  );
}
