import React, { useState } from "react";
import TagManager from "react-gtm-module";
import { Tag, Form, Button, message } from "antd";

import notify from "../../../assets/images/notify-bank.svg";
import {
  formatMoney,
  formatPhoneNumber,
} from "../../../utilities/helperFunctions";
import payoutLogoOne from "../../../assets/images/blue-payout.svg";
import makeAPICall from "../../../utilities/apiUtils";
import Loader from "../../../components/Loader";
import PinInput from "react-pin-input";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_completed_transaction",
    transaction_type: "add_money_momo",
  },
};

export default function ThirdMomo({
  amount,
  currency,
  country,
  phoneNumber,
  prev,
  closeModal,
  gotoHome,
}) {
  const [lastView, setLastView] = useState(false);
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState();
  const [activeToken, setActiveToken] = useState(false);

  const nextLast = () => {
    setLastView(true);
  };

  const makeApi = () => {
    setLoader(true);
    const data = {
      currency: currency,
      amount: amount,
      country: country,
      phone: phoneNumber,
    };
    return makeAPICall({
      path: "transactions/collections/momo",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        message.success("Transaction initiated", 5);
        setLoader(false);
        TagManager.dataLayer(tagManagerArgs);
        const { transaction } = res.data;
        // if (transaction.currency === "GHS") {
        //   window.location.href = transaction?.pesapotResponse?.result?.otp_url;
        // } else {
        //   setDetails(transaction);
        //   nextLast();
        // }
        setDetails(transaction);
        if (transaction.currency === "GHS") {
          return setActiveToken(true);
        }
        nextLast();
      })
      .catch((err) => {
        setLoader(false);
        message.error(err.message, 5);
      });
  };

  const handleOtpSubmit = (otpInput) => {
    setLoader(true);
    const data = {
      transaction_id: details?.pesapotId,
      otp: otpInput,
    };
    return makeAPICall({
      path: "transactions/collections/sendGhsMomoOTP",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        message.success("Transaction initiated", 5);
        setLoader(false);
        nextLast();
      })
      .catch((err) => {
        setLoader(false);
        console.log("Error Sent", err.error);
        message.error(
          err.message ||
            err.message?.error?.message ||
            "Otp Verification failed. please check and try again",
          5
        );
      });
  };

  return (
    <>
      {lastView ? (
        <>
          <div className="banktransfer-others">
            <div className="text-center">
              <img src={payoutLogoOne} alt="" width="30px" />
            </div>
            <br />
            <h2 className="text-center">Transaction In progress</h2>
            <p className="text-center">
              MOMO Top up to your wallet has been initiated, you would be
              notified with a transaction status update shortly
            </p>
            <br />
            <div className="payout-receipt">
              <div className="d-flex">
                <p className="payout-sent">Transaction</p>
                <p className="ms-auto">
                  <Tag color="geekblue">pending</Tag>
                </p>
              </div>
              <hr />
              <div className="d-flex">
                <p className="payout-sent">
                  <span style={{ color: "#696779", fontSize: "14px" }}>
                    Sent time
                  </span>
                  <br />
                  10:25pm
                </p>
                <p className="ms-auto text-right payout-sent">
                  <span style={{ color: "#696779", fontSize: "14px" }}>
                    Transaction Id
                  </span>
                  <br />
                  {details?.transactionId}
                </p>
              </div>
              <div className="d-flex">
                <p className="payout-sent">
                  <span style={{ color: "#696779", fontSize: "14px" }}>
                    Sent amount
                  </span>
                  <br />
                  {currency}&nbsp; {formatMoney(amount)}
                </p>
                <p className="ms-auto text-right payout-sent">
                  <span style={{ color: "#696779", fontSize: "14px" }}>
                    Transaction type
                  </span>
                  <br />
                  MOMO top up
                </p>
              </div>
              <div className="d-flex">
                <p className="payout-sent">
                  <span style={{ color: "#696779", fontSize: "14px" }}>
                    Top up by
                  </span>
                  <br />
                  Sent from
                </p>
                <p className="ms-auto text-right payout-sent">
                  <span style={{ color: "#696779", fontSize: "14px" }}>
                    Transaction type
                  </span>
                  <br />
                  {phoneNumber}
                </p>
              </div>
              <p className="payout-view-more">
                <span style={{ color: "#2f80ed" }}>
                  <i className="fa fa-download"></i>&nbsp; Download
                </span>
                &nbsp; these details as a PDF
              </p>
              <Form>
                <Form.Item>
                  <Button
                    size="large"
                    type="default"
                    className="grey-btn"
                    style={{ width: "49%" }}
                    onClick={gotoHome}
                  >
                    Create new Topup
                  </Button>
                  <Button
                    size="large"
                    type="default"
                    className="pay-blue-btn"
                    style={{ width: "49%", marginLeft: "5px" }}
                    onClick={closeModal}
                  >
                    Go to dashboard
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      ) : (
        <div className="banktransfer-others" style={{ width: "100%" }}>
          {activeToken ? (
            <div>
              <h2 className="text-center">MOMO transfer</h2>
              <p className="text-center">
                We have sent a verification code to your phone number
              </p>

              <br />
              <br />

              {loader ? (
                <button
                  style={{
                    background: "transparent",
                    outline: "none",
                    border: "none",
                    marginTop: "15px",
                  }}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <Loader dark={true} /> Verifying OTP and Processing Request
                  </span>
                </button>
              ) : (
                <PinInput
                  focus
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  inputStyle={{
                    border: "1px solid #CBCAE5",
                    borderRadius: "8px",
                    color: "#949494",
                  }}
                  length={6}
                  type="numeric"
                  onComplete={handleOtpSubmit}
                />
              )}

              <br />
              <br />

              <Button
                size="large"
                type="default"
                className="grey-btn"
                style={{ width: "100%" }}
                onClick={closeModal}
              >
                Cancel Transaction
              </Button>

              <br />
              <br />
              <h3 className="notaBena">
                * Input the token sent to your phone to be able to get
                notification that will enable the collections happen.
              </h3>
            </div>
          ) : (
            <>
              <h2 className="text-center">MOMO transfer</h2>
              <p className="text-center">
                You are about to make a MOMO transfer
              </p>
              <br />
              <div className="greyed-bank-transfer">
                <p className="text-center">Amount to be transferred</p>
                <h2 className="text-center">
                  {currency}&nbsp;{formatMoney(amount)}
                </h2>
              </div>
              <br />
              <div className="d-flex">
                <div style={{ marginRight: "10px" }}>
                  <img src={notify} alt="notify" />
                </div>
                <div className="align-self-center">
                  <h1 className="header-bank">Read this</h1>
                </div>
              </div>
              <br />
              <ul className="add-money-ul">
                <li>
                  The amount to be funded to your Eversend wallet will be
                  deducted from your MOMO account with phone your number
                  {formatPhoneNumber(phoneNumber)}.
                </li>
                <li>
                  If you do not recognize the phone number please contact
                  support or click “go back” to choose another number
                </li>
                <li>
                  Ensure that your MOMO account is funded with the amount you
                  want to top up.
                </li>
                <li>
                  If the amount you want to add to your Eversend wallet is not
                  in your MOMO account with phone number
                  {formatPhoneNumber(phoneNumber)}. The transaction would be
                  considered as a failed transaction
                </li>
              </ul>
              <br />
              <Form layout="vertical">
                <Form.Item>
                  <Button
                    size="large"
                    type="default"
                    className="grey-btn"
                    onClick={prev}
                    style={{ width: "47%" }}
                  >
                    Go Back
                  </Button>
                  <Button
                    size="large"
                    type="default"
                    className="pay-blue-btn"
                    onClick={makeApi}
                    disabled={loader}
                    loading={loader}
                    style={{ width: "47%", float: "right" }}
                  >
                    Add money
                  </Button>
                </Form.Item>
              </Form>
              <h3 className="notaBena">
                *By clicking “Add money” you agree to the terms and conditions
                of adding money to your Eversend wallet via MOMO.
              </h3>
            </>
          )}
        </div>
      )}
    </>
  );
}
