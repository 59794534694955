import React from "react";

import _ from "lodash";

import { useDispatch, useSelector } from "../../../../utilities/hooks";
import {
  toggleExchangeModalAction,
  toggleSendMoneyModalAction,
  toggleAddMoneyModalAction,
} from "../../../../services/actions";
import { Button, Dropdown, Menu } from "antd";
import TagManager from "react-gtm-module";
import { AccessControl } from "accesscontrol";
import bankIcon from "../../../../assets/images/radio-buttons/bankDropdown.svg";
import mobileMoneyIcon from "../../../../assets/images/radio-buttons/mobileMoneyDropdown.svg";
import requestIcon from "../../../../assets/images/radio-buttons/requestMoney.svg";
import cryptoIcon from "../../../../assets/images/cryptoBit.svg";

const tagManagerArgsAdd = {
  dataLayer: {
    event: "b2b_add_money_btn",
  },
};

const tagManagerArgsSend = {
  dataLayer: {
    event: "b2b_send_money_btn",
  },
};

const tagManagerArgsExchange = {
  dataLayer: {
    event: "b2b_exchange_btn",
  },
};

export default function OverviewRadioButtonGroup({ wallets, loading, user }) {
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.RoleReducer);
  const ac = new AccessControl(roles?.permissions);
  // const permission = ac.can(user.role).createAny("payouts");

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }
  // console.log(permission.granted, "testone");
  const mainWallet = _.find(wallets, ({ isMain }) => isMain === true);

  const enabledWallets = _.remove(
    _.concat([], wallets),
    ({ enabled }) => enabled
  );

  const cryptoWallets = wallets.filter((wallet) => {
    return wallet.isCrypto === true && wallet.enabled === true;
  });

  const isActive = enabledWallets?.some((item) => item?.isCrypto === true);

  function handleOpenExchangeModal() {
    TagManager.dataLayer(tagManagerArgsExchange);
    dispatch(toggleExchangeModalAction(_.get(mainWallet, "currency", "")));
  }

  function handleOpenPayoutModal() {
    TagManager.dataLayer(tagManagerArgsSend);
    dispatch(toggleSendMoneyModalAction());
  }

  function handleOpenAddMoneyModal(item) {
    if (disableButtons) {
      return false;
    } else {
      TagManager.dataLayer(tagManagerArgsAdd);
      if (item === "crypto") {
        dispatch(toggleAddMoneyModalAction(cryptoWallets[0], item));
      } else {
        dispatch(toggleAddMoneyModalAction(mainWallet, item));
      }
    }
  }

  const areAllWalletFalse = wallets?.every((item) => !item.enabled);

  const disableButtons = !wallets || wallets.length === 0 || areAllWalletFalse;

  const userMenu = (
    <Menu className="header-dropdown-user-container-action-buttons">
      <Menu.Item
        key="1"
        className="d-flex align-items-center mb-3"
        onClick={() => handleOpenAddMoneyModal("bank")}
      >
        <img src={bankIcon} alt="bank" />
        <p className="ms-3">Bank</p>
      </Menu.Item>
      <Menu.Item
        key="2"
        className="d-flex align-items-center mb-3"
        onClick={() => handleOpenAddMoneyModal("momo")}
      >
        <img src={mobileMoneyIcon} alt="mobile money" />
        <p className="ms-3">Mobile money</p>
      </Menu.Item>
      <Menu.Item
        key="3"
        className="d-flex align-items-center mb-3"
        onClick={() => handleOpenAddMoneyModal("eversend")}
      >
        <img src={requestIcon} alt="request money" />
        <p className="ms-3">Eversend</p>
      </Menu.Item>
      {isActive && (
        <Menu.Item
          key="4"
          className="d-flex align-items-center"
          onClick={() => handleOpenAddMoneyModal("crypto")}
        >
          <img src={cryptoIcon} alt="request money" />
          <p className="ms-2">Crypto</p>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="dashboard-overview-button-group">
      {checkAccess("collections").granted ? (
        <Dropdown
          overlay={userMenu}
          trigger={["click"]}
          placement="bottomRight"
        >
          <Button
            type="primary"
            // onClick={handleOpenAddMoneyModal}
            disabled={disableButtons}
          >
            Fund wallets
          </Button>
        </Dropdown>
      ) : null}

      {checkAccess("payouts").granted ? (
        <Button onClick={handleOpenPayoutModal} disabled={disableButtons}>
          Send money
        </Button>
      ) : null}

      {checkAccess("exchanges").granted ? (
        <Button onClick={handleOpenExchangeModal} disabled={disableButtons}>
          Exchange
        </Button>
      ) : null}
    </div>
  );
}
