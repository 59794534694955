/* eslint-disable no-undef */
import React from "react";

import { Table, Tag, Avatar, Input, Tabs } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import randomColor from "randomcolor";
import { formatDate } from "../../../utilities/helperFunctions";
// import addTeam from "../../../assets/images/add-team.svg";

const { TabPane } = Tabs;

export default function TeamInfo({
  handleChange,
  handleOpenModal,
  handleOpenEditModal,
  handleOpenDeleteModal,
  users,
  user,
  userRole,
}) {
  // console.log(users,'teste')
  const columns = [
    {
      title: "User",
      dataIndex: "id",
      width: "10%",
      render: (id, row) => {
        return (
          <Avatar
            size="large"
            style={{ backgroundColor: randomColor(), verticalAlign: "middle" }}
          >
            {row?.firstName.charAt(0)}
            {row?.lastName.charAt(0)}
          </Avatar>
        );
      },
    },
    {
      dataIndex: "firstName",
      width: "30%",
      render: (firstName, row) => {
        return (
          <>
            <p style={{ color: "#1C223C" }}>
              {firstName} {row?.lastName}
            </p>
            <span style={{ color: "#48484D" }}>{row?.email}</span>
          </>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      render: (phone) => {
        return (
          <>
            <p style={{ color: "#1C223C" }}>{phone}</p>
          </>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => {
        if (role === "owner") {
          return <Tag color="red">{role}</Tag>;
        } else if (role === "developer") {
          return <Tag color="green">{role}</Tag>;
        } else {
          return <Tag color="geekblue">{role}</Tag>;
        }
      },
    },
    {
      title: "Activity",
      dataIndex: "lastLoggedIn",
      render: (lastLoggedIn) => {
        return (
          <>
            <p style={{ color: "#1C223C" }}>
              Last Login, {formatDate(lastLoggedIn)}
            </p>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: "15%",
      render: (id, row) => {
        return userRole === "owner" ? (
          <div className="d-flex justify-content-between">
            {user === row?.email && row?.role === "owner" ? null : (
              <>
                <p
                  onClick={() => handleOpenEditModal(row)}
                  style={{ cursor: "pointer", marginRight: "10px" }}
                >
                  Edit <EditOutlined />
                </p>
                <p
                  onClick={() => handleOpenDeleteModal(row)}
                  style={{ cursor: "pointer" }}
                >
                  Delete <DeleteOutlined style={{ color: "red" }} />
                </p>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  // function getRow(item) {
  //   // console.log(item);
  // }

  const pendingUsers = users.filter((item) => {
    return item.invitationStatus === "pending";
  });

  return (
    <>
      <br />
      <div className="bg-white p-2" style={{ position: "relative" }}>
        <div className="users-filters">
          <div className="d-flex">
            <div className="align-self-center">
              <Input
                size="large"
                placeholder="Search email, user name"
                prefix={<SearchOutlined className="faded" />}
                className="header-search"
                style={{ height: "100%", maxWidth: "50%" }}
                onChange={handleChange}
              />
            </div>

            <div>
              <p>&nbsp;</p>
            </div>
            <div className="ms-auto align-self-center">
              <button
                type="button"
                onClick={handleOpenModal}
                className="btn btn-primary"
                style={{
                  borderRadius: "4px",
                  color: "white",
                  fontSize: "16px",
                  padding: "5px 20px",
                }}
              >
                <i className="fa fa-plus"></i>&nbsp; Add new member
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Tabs defaultActiveKey="1">
              <TabPane tab="All users" key="1">
                <Table
                  columns={columns}
                  dataSource={users}
                  pagination={false}
                />
              </TabPane>
              <TabPane tab="pending invitations" key="2">
                <Table
                  columns={columns}
                  dataSource={pendingUsers}
                  pagination={false}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
