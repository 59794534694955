import React from "react";
import payoutLogoOne from "../../../../assets/images/successIcon.svg";
import { Form, Button, Row, Col } from "antd";
import {
  formatBalance,
  formatTime,
} from "../../../../utilities/helperFunctions";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "40px",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: "20%",
  },
  rowTwo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
  },
  imageSocial: {
    width: "15px",
    height: "15px",
  },
  imageGray: {
    width: "80px",
    height: "20px",
  },
  date: {
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#444052",
  },
  mainText: {
    marginTop: 30,
    marginBottom: 20,
  },
  userName: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    color: "#344054",
    marginTop: 10,
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 13,
    color: "#040052",
    padding: "10px 0",
    borderBottom: "1px solid #5A50FE",
  },
  newSection: {
    flexDirection: "row",
    marginTop: 20,
  },
  leftText: {
    color: "#444052",
    fontSize: 13,
    fontWeight: 400,
    fontStyle: "normal",
    marginRight: "20px",
    marginBottom: "10px",
    width: "25%",
  },
  rightText: {
    color: "#344054",
    fontSize: 13,
    fontWeight: 700,
    fontStyle: "normal",
    marginBottom: "10px",
  },
});

export default function Fourth({ transDetails, newPayout, gotoHome }) {
  const [viewOne, setViewOne] = React.useState(true);
  const [viewTwo, setViewTwo] = React.useState(false);

  let accountDetails = transDetails;

  const handleClick = () => {
    setViewOne(false);
    setViewTwo(true);
  };
  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.row}>
          <Image style={styles.image} src="/eversend_receipt_logo.png" />
          <Text style={styles.date}>
            {formatTime(accountDetails?.createdAt)}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>
            Hi {accountDetails?.user?.firstName},
          </Text>
          <Text style={styles.userName}>
            You have created a payout transaction on your Eversend wallet
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.title}>Transaction Details</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>ID</Text>
          <Text style={styles.rightText}>{accountDetails?.transactionId}</Text>
        </View>

        <View style={styles.newSection}>
          <Text style={styles.leftText}>Beneficiary</Text>
          <Text style={styles.rightText}>
            {accountDetails?.beneficiary?.firstName}&nbsp;
            {accountDetails?.beneficiary?.lastName}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Amount</Text>
          <Text style={styles.rightText}>
            {accountDetails?.currency} {formatBalance(accountDetails?.amount)}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Balance Before</Text>
          <Text style={styles.rightText}>
            {accountDetails?.currency}&nbsp;
            {formatBalance(accountDetails?.balanceBefore)}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Balance After</Text>
          <Text style={styles.rightText}>
            {accountDetails?.currency}&nbsp;
            {formatBalance(accountDetails?.balanceAfter)}
          </Text>
        </View>

        <View style={styles.mainText}>
          <Text style={styles.userName}>Thanks,</Text>
          <Text style={styles.userName}>The Eversend team</Text>
        </View>
        <View style={styles.rowTwo}>
          <Image style={styles.imageGray} src="/eversend_logo_gray.png" />
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="payout-receipt">
      {viewOne && (
        <div className="m-4 pt-4">
          <Row justify="center" align="middle">
            <Col>
              <h6 className="text-bold color-bold text-center">
                <img src={payoutLogoOne} alt="" width={60} />
              </h6>

              <br />
              <h4>Payout Successful</h4>
            </Col>
          </Row>
          <br />

          <p className="text-center">
            You have paid &nbsp;
            <b>
              {accountDetails?.currency}
              {formatBalance(accountDetails?.amount)}
            </b>
            &nbsp; to &nbsp;
            <b>
              {accountDetails?.beneficiary?.firstName}&nbsp;
              {accountDetails?.beneficiary?.lastName}&nbsp;
            </b>
            <br />
          </p>
          <br />
          <p className="text-warning text-center blink">
            payment may take 1 - 30 mins to be delivered. Contact support if not
            received
          </p>

          <br />

          <p onClick={handleClick} className="text-right">
            View Full Details&nbsp; <i className="fa fa-angle-right"></i>
          </p>

          <br />
          <Form>
            <Form.Item>
              <Button
                size="large"
                type="default"
                className="grey-btn"
                style={{ width: "100%" }}
                onClick={newPayout}
              >
                Create New Payout
              </Button>
              <br />
              <br />
              <Button
                size="large"
                type="default"
                className="pay-blue-btn"
                style={{ width: "100%" }}
                onClick={gotoHome}
              >
                Go To Dashboard
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      {viewTwo && (
        <div>
          <div className="m-4 ">
            <Row justify="center" align="middle">
              <Col>
                <h6 className="text-bold color-bold text-center">
                  <img src={payoutLogoOne} alt="" width={60} />
                </h6>

                <h4>Payout Successful</h4>
              </Col>
            </Row>
            <br />
            <p className="payout-sent text-center">
              Payment to {accountDetails?.beneficiary?.firstName}&nbsp;
              {accountDetails.beneficiary.lastName} has been initiated.
            </p>
          </div>
          <div className="d-flex">
            <h5>Transaction</h5>
          </div>
          <hr />
          <h6>Sender Details</h6>
          <div className="d-flex">
            <p className="payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Sent Time
              </span>
              <br />
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Transaction Id
              </span>
              <br />
              <span style={{ color: "#696779", fontSize: "14px" }}>Amount</span>
            </p>
            <p className="ms-auto text-right payout-sent">
              {formatTime(accountDetails?.createdAt)}
              <br />
              {accountDetails?.transactionId}
              <br />
              {accountDetails?.currency}&nbsp;
              {accountDetails?.amount}
            </p>
          </div>
          <div className="d-flex">
            <p className="payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Sent By
              </span>
              <br />
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Sent From
              </span>
            </p>
            <p className="ms-auto text-right payout-sent">
              {accountDetails?.user?.firstName}&nbsp;
              {accountDetails?.user?.lastName}
              <br />
              {accountDetails?.sourceCurrency} wallet
            </p>
          </div>

          <div className="d-flex">
            <p className="payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Transaction Fee
              </span>
              <br />
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Exchange Rate
              </span>
              <br />
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Balance Before
              </span>
              <br />
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Balance After
              </span>
            </p>
            <p className="ms-auto text-right payout-sent">
              {accountDetails?.meta?.fees ? accountDetails?.currency : ""}
              {accountDetails?.meta?.fees}
              <br />
              {accountDetails?.meta?.fees ? accountDetails?.currency : ""}
              {accountDetails?.meta?.fees}
              <br />
              {accountDetails?.balanceBefore}
              <br />
              {accountDetails?.balanceAfter}
            </p>
          </div>
          <hr />
          <h6>Recipient Details</h6>
          <div className="d-flex">
            <p className="payout-sent">
              <span style={{ color: "#696779", fontSize: "14px" }}>
                Recipient
              </span>
            </p>
            <p className="ms-auto text-right payout-sent">
              {accountDetails?.beneficiary?.firstName}&nbsp;
              {accountDetails?.beneficiary?.lastName}
            </p>
          </div>

          {accountDetails?.beneficiary?.bankCode ? (
            <div className="d-flex">
              <p className="payout-sent">
                <span style={{ color: "#696779", fontSize: "14px" }}>
                  Bank Name
                </span>
                <br />
                <span style={{ color: "#696779", fontSize: "14px" }}>
                  Bank Account Number
                </span>
              </p>
              <p className="ms-auto text-right payout-sent">
                {accountDetails?.beneficiary?.bankName}
                <br />
                {accountDetails?.beneficiary?.bankAccountNumber}
              </p>
            </div>
          ) : null}
          <PDFDownloadLink document={<MyDocument />} fileName="payout.pdf">
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <p className="payout-view-more">
                  <span style={{ color: "#2f80ed" }}>
                    <i className="fa fa-download"></i>&nbsp; Download
                  </span>
                  &nbsp; these details as a PDF
                </p>
              )
            }
          </PDFDownloadLink>
          {/* <h1 className="beneficiary-payout">Add as benefitiary</h1> */}
          <Form>
            <Form.Item>
              <Button
                size="large"
                type="default"
                className="grey-btn"
                style={{ width: "49%" }}
                onClick={newPayout}
              >
                Create New payout
              </Button>
              <Button
                size="large"
                type="default"
                className="pay-blue-btn"
                style={{ width: "49%", marginLeft: "5px" }}
                onClick={gotoHome}
              >
                Go To Dashboard
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}
