import React, { useState } from "react";
import { Tabs, Button, Input, Form } from "antd";
// import suitCase from "../../../assets/images/suit-case.svg";
import personCase from "../../../assets/images/person-icon.svg";
import GroupIcon from "../../../assets/images/users.svg";

import styles from "./payout.module.scss";

export default function First({ onClick, showBenModal }) {
  const [viewOne, setViewOne] = useState(true);
  const [viewTwo, setViewTwo] = useState(false);
  //const [viewThree, setViewThree] = useState(false);
  const { TabPane } = Tabs;

  const showVidewTwo = (item) => {
    if (item === "person") {
      setViewOne(false);
      setViewTwo(true);
    } else if (item === "beneficiary") {
      showBenModal();
    } else {
      setViewOne(false);
      setViewTwo(false);
    }
  };

  function callback(key) {}

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  const onChangeInputValue = (event) => {
    setFirstName(event.target.value);
    // setViewThree(true);
  };

  const onChangeInputValueTwo = (event) => {
    setLastName(event.target.value);
    // setViewThree(true);
  };

  const goBack = () => {
    setViewOne(true);
    setViewTwo(false);
    // setViewThree(false);
  };

  function handleKeyPress(e) {
    const charCode = e.which || e.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault(); // Prevent input of numeric characters
    }
  }

  return (
    <div className={`first-payout  d-flex flex-column ${styles.wrapper}`}>
      <Tabs defaultActiveKey="1" onChange={callback} centered>
        <TabPane tab="Single payout" key="1">
          <br />
          {viewOne && (
            <div className="first-view-payout">
              <h1>Who are you paying?</h1>
              <div className="gb-bg" onClick={() => showVidewTwo("person")}>
                <div className="d-flex align-items-center">
                  <img src={personCase} alt="" />
                  <p>Persons</p>
                </div>
              </div>
              <div
                className="gb-bg"
                onClick={() => showVidewTwo("beneficiary")}
              >
                <div className="d-flex align-items-center">
                  <img src={GroupIcon} alt="" />
                  <p>Beneficiary</p>
                </div>
              </div>
              {/* <div className="gb-bg">
                <div className="d-flex">
                  <img src={suitCase} alt="" />
                  <p>Company</p>
                </div>
              </div> */}
            </div>
          )}
          {viewTwo && (
            <>
              <Form layout="vertical" className={`${styles.form}`}>
                <Form.Item label="Enter person's name">
                  <Input.Group compact>
                    <Input
                      placeholder="first name"
                      value={firstName}
                      onChange={onChangeInputValue}
                      type="text"
                      style={{ width: "50%" }}
                      onKeyPress={handleKeyPress}
                    />
                    <Input
                      placeholder="last name"
                      value={lastName}
                      onChange={onChangeInputValueTwo}
                      type="text"
                      style={{ width: "50%" }}
                      onKeyPress={handleKeyPress}
                    />
                  </Input.Group>
                </Form.Item>

                {/* {profile === null ? (
                  ""
                ) : (
                  <>
                    {viewThree && (
                      <p className="input-par">
                        Pay someone new: <span>{profile}</span>
                      </p>
                    )}
                  </>
                )} */}

                <Form.Item>
                  <Button
                    size="large"
                    type="default"
                    className="grey-btn"
                    onClick={goBack}
                    style={{ width: "49%" }}
                  >
                    Go Back
                  </Button>
                  <Button
                    size="large"
                    type="default"
                    className="pay-blue-btn"
                    onClick={() => onClick(firstName, lastName)}
                    disabled={!firstName && !lastName}
                    style={{ width: "49%", marginLeft: "5px" }}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </TabPane>
        {/* <TabPane tab="Bulk payout" key="2">
          Coming soon
        </TabPane> */}
      </Tabs>
    </div>
  );
}
