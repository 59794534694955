import React, { useState } from "react";
import EmailVerification from "./EmailVerification";
import PhoneVerification from "./PhoneVerification";
import { useHistory } from "react-router-dom";
import makeAPICall from "../../../utilities/apiUtils";
import { message } from "antd";
import Loader from "../../../components/Loader";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_phase1_registration",
  },
};

export default function SeventhForm({ title, inputDetails }) {
  const navigate = useHistory();
  const [formDetails, setFormDetails] = useState(inputDetails);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(formDetails, "Testing");

  const changeEmail = (email) => {
    setFormDetails({ ...formDetails, email: email });
  };

  const changePhone = (details) => {
    // Remove the leading zero if present
    const adjustedValue = details?.phoneNumber.startsWith("0")
      ? details?.phoneNumber.slice(1)
      : details?.phoneNumber;
    setFormDetails({
      ...formDetails,
      phone: `${details?.selectedCountry?.dial_code}${adjustedValue}`,
      localPhone: adjustedValue,
      dialCode: details?.selectedCountry?.dial_code,
      country: details?.selectedCountry?.code,
    });
  };

  const verifyEmail = () => {
    setIsEmailVerified(true);
  };

  const verifyPhone = () => {
    setIsPhoneVerified(true);
  };

  const handleSubmit = () => {
    // createBusinessAccount
    const adjustedPhoneValue = formDetails.phone.startsWith("+")
      ? formDetails.phone.slice(1)
      : formDetails.phone;
    setIsLoading(true);
    const data = {
      businessCountry: formDetails.businessCountry,
      businessRegistered:
        formDetails.businessRegistered === "yes" ? true : false,
      businessType: formDetails.businessType.toUpperCase(),
      country: formDetails.country,
      email: formDetails.email,
      firstName: formDetails.firstName,
      lastName: formDetails.lastName,
      password: formDetails.password,
      phone: adjustedPhoneValue,
    };
    return makeAPICall({
      path: "auth/createBusinessAccount",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        TagManager.dataLayer(tagManagerArgs);
        setIsLoading(false);
        message.success("Your account has been created successfully", 5);
        setTimeout(function () {
          navigate.push("/login");
        }, 3000);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message, 5);
      });
  };

  return (
    <div>
      <h2>{title}</h2>
      <EmailVerification
        formDetails={formDetails}
        verified={verifyEmail}
        email={formDetails?.email}
        changeEmail={changeEmail}
      />
      <PhoneVerification
        verified={verifyPhone}
        formDetails={formDetails}
        changePhone={changePhone}
        emailVerified={isEmailVerified}
      />
      <br />
      <button
        type="submit"
        disabled={!(isEmailVerified && isPhoneVerified) || isLoading}
        className="submit-reg"
        onClick={handleSubmit}
      >
        {isLoading ? (
          <span className="d-flex align-items-center justify-content-between">
            <Loader dark={false} />
          </span>
        ) : (
          "Continue"
        )}
      </button>
      <p className="terms-cond">
        By clicking the “Continue” button, you agree to Eversend’s {""}
        <a
          href="https://eversend.co/terms-of-service"
          className="color-primary"
        >
          Terms {""}
        </a>
        <span>of use and {""}</span>
        <a href="https://eversend.co/privacy-policy" className="color-primary">
          Privacy policy.
        </a>
      </p>
    </div>
  );
}
