import React from "react";
import close from "../../assets/images/Close.svg";
import styles from "./styled-modal.module.scss";

export default function StyledModal({ children, show, onClose }) {
  return (
    <>
      {show ? (
        <div className={styles.overlay}>
          <div className={styles.header}>
            <div onClick={onClose} className={styles.close}>
              <img src={close} alt="close modal" />
            </div>
          </div>
          <div className={styles.body}>{children}</div>
        </div>
      ) : null}
    </>
  );
}
