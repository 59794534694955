import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const isMobileScreen = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    setIsMobile(isMobileScreen);
  }, [isMobileScreen]);

  return isMobile;
};

export default useMobile;
