import React, { useState } from "react";
import { Formik } from "formik";
import StyledModal from "../styled-modal";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import CardDetails from "../CardDetails";
import { useDispatch } from "../../../utilities/hooks";
import {
  fetchCardsAction,
  fetchWalletsAction,
} from "../../../services/actions";
import StepsWrapper from "../../StepsWrapper";

export default function CreateCardModal({ show, onClose }) {
  const dispatch = useDispatch();

  const [cardDetailsModal, setCardDetailsModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const [card, setCard] = useState({});
  const [cardDetails, setCardDetailsNow] = useState({});

  function onCloseModalDetails() {
    setCardDetailsModal(false);
    dispatch(fetchCardsAction({}));
    dispatch(fetchWalletsAction());
    onClose();
  }

  let colors = {
    blue: "#5a50fe",
    black: "#040052",
    purple: "#FF3599",
    orange: "#00C279",
    yellow: "#EAA5FF",
  };

  const cardModal = (data) => {
    setCardDetailsNow({ ...cardDetails, ...data });
    setCardDetailsModal(true);
  };

  const setCardDetails = (data) => {
    setCard({ ...card, ...data });
  };

  const steps = [
    {
      title: "Choose Recipient and Source Wallet",
      content: (
        <First
          next={() => next()}
          colors={colors}
          card={card}
          cardDetails={(data) => setCardDetails(data)}
        />
      ),
    },
    {
      title: "Select Your Card's Style",
      content: (
        <Second
          next={() => next()}
          card={card}
          colors={colors}
          cardDetails={(data) => setCardDetails(data)}
        />
      ),
    },
    {
      title: "Payment and Card Creation",
      content: (
        <Third
          next={() => next()}
          card={card}
          // colors={colors}
          cardDetails={(data) => setCardDetails(data)}
        />
      ),
    },
    {
      title: "Card Creation Status",
      content: (
        <Fourth
          next={() => next()}
          card={card}
          colors={colors}
          close={closeFinal}
          cardModal={(data) => cardModal(data)}
          cardDetails={(data) => setCardDetails(data)}
        />
      ),
    },
  ];

  function next() {
    setCurrent(current + 1);
  }

  function onCloseModal(item) {
    setCurrent(item);
    onClose();
  }

  function closeFinal() {
    onClose();
    dispatch(fetchCardsAction({}));
    dispatch(fetchWalletsAction());
  }

  const handleNav = (value) => {
    if (value > current) {
      return;
    }
    setCurrent(value);
  };

  const prev = () => setCurrent((value) => value - 1);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        localPhone: "",
        dialCode: "",
        country: "",
        city: "",
        zip: "",
        state: "",
        idType: "",
        idNumber: "",
        address: "",
        cardBrand: "",
        selectedUser: null,
        cardTitle: cardDetails.title,
        selectedBackground: "#5a50fe",
        selectedColor: "blue",
        isNonSubscription: true,
      }}
    >
      {({ resetForm }) => (
        <div>
          {cardDetailsModal ? (
            <CardDetails
              cardDetailsAll={cardDetails}
              tabIndexNow={0}
              onClose={onCloseModalDetails}
              show={cardDetailsModal}
            />
          ) : (
            <>
              <StyledModal
                show={show}
                onClose={() => {
                  onCloseModal(0);
                  resetForm();
                }}
              >
                <StepsWrapper
                  steps={steps}
                  currentStep={current}
                  onChange={handleNav}
                  title="Create Card"
                  onGoBack={prev}
                  hideBackButton={current === 3}
                >
                  <div className="create-card-container">
                    {steps[current].content}
                  </div>
                </StepsWrapper>
              </StyledModal>
            </>
          )}
        </div>
      )}
    </Formik>
  );
}
