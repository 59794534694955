import React, { useEffect, useState } from "react";
import SharedAuthLayout from "../../../components/layouts/Auth.layout.jsx";
import { useIntercom } from "react-use-intercom";
import { useSelector, useDispatch } from "../../../utilities/hooks.js";
import { loginAction } from "../../../services/actions/index.js";
import { useHistory, Link } from "react-router-dom";
import ClosedEye from "../../../assets/images/Eye_Closed.svg";
import OpenEye from "../../../assets/images/Eye_Open.svg";

import {
  fetchSourceAction,
  forgotPasswordActionReset,
} from "../../../services/actions/index.js";
import Loader from "../../../components/Loader.jsx";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "b2b_login_btn",
  },
};

export default function LoginContainer() {
  const navigate = useHistory();
  const [inputTypeOne, setInputTypeOne] = useState("password");
  const { boot } = useIntercom();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loginReducer);
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });
  boot({
    isB2B: "B2B",
  });

  function handleChange(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  function handleSubmit() {
    TagManager.dataLayer(tagManagerArgs);
    const { email, password } = inputValues;
    dispatch(
      loginAction({
        email,
        password,
      })
    );
  }

  useEffect(() => {
    dispatch(fetchSourceAction());
    dispatch(forgotPasswordActionReset());
  }, [dispatch]);

  useEffect(() => {
    window.localStorage.clear();
  }, []);

  const togglePasswordVisibilityOne = () => {
    setInputTypeOne(inputTypeOne === "password" ? "text" : "password");
  };

  return (
    <SharedAuthLayout title="Log into your account">
      <div style={{ marginTop: "10px" }}>
        <div className="form-group">
          <label htmlFor="email" className="label-form">
            Email address
          </label>
          <input
            type="email"
            value={inputValues.email}
            name="email"
            onChange={handleChange}
            placeholder="eg. johndoe@gmail.com"
            className="auth-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="label-form">
            Password
          </label>
          <div style={{ position: "relative" }}>
            <input
              type={inputTypeOne}
              value={inputValues.password}
              name="password"
              onChange={handleChange}
              placeholder="Password"
              className="auth-input"
            />
            <button
              onClick={togglePasswordVisibilityOne}
              style={{
                outline: "none",
                background: "transparent",
                position: "absolute",
                right: "10px",
                top: "16px",
                width: "20px",
                border: "none",
              }}
            >
              {inputTypeOne === "password" ? (
                <img src={ClosedEye} alt="close" />
              ) : (
                <img src={OpenEye} alt="open" />
              )}
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-5">
          {/* <Checkbox onChange={onChange}>Remember for 1 week.</Checkbox> */}
          <button
            className="external-link"
            onClick={() => navigate.push("/forgot-password")}
          >
            Forgot password
          </button>
        </div>
        <button
          type="submit"
          disabled={
            !(inputValues.email.trim() && inputValues.password.trim()) ||
            loading
          }
          // disabled={inputValues.email === "frank@eversend.co" ? false : true}
          onClick={handleSubmit}
          className="submit-btn"
        >
          {loading ? (
            <span className="d-flex align-items-center justify-content-between">
              <Loader dark={false} />
            </span>
          ) : (
            "Login"
          )}
        </button>
        <div className="reg-link">
          <p>
            Don't have an account? <Link to="/signup">Create account</Link>
          </p>
        </div>
      </div>
    </SharedAuthLayout>
  );
}
