import React, { useState, useEffect } from "react";
import { Button, Form, Radio, Space, Input, Select, message, Spin } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import bank from "../../../assets/images/bank_payment.svg";
import momo from "../../../assets/images/momo_payment.svg";
import { fetchBanksAction } from "../../../services/actions";
import makeAPICall from "../../../utilities/apiUtils";
import styles from "./payout.module.scss";

export default function BankFormContainer({ goBack, handleClick }) {
  const Option = Select.Option;
  const { banks } = useSelector((state) => state.BanksReducer);
  const { countries } = useSelector((state) => state.CountryReducer.countries);

  const dispatch = useDispatch();

  const [paymentMethod, setPaymentMethod] = useState("");
  const [showType, setShowType] = useState(false);
  const [banksSelect, setBanksSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [receipientDetails, setReceipientDetails] = useState({
    bankName: "",
    bankCode: "",
    country: "",
    bankAccountName: "",
    bankAccountNumber: "",
    phoneNumber: "",
  });

  const countryArray = countries?.map((item) => item?.country.toLowerCase());

  useEffect(() => {
    dispatch(fetchBanksAction({}));
  }, [dispatch]);

  function onSelectChange(value, name) {
    if (name === "country") {
      setReceipientDetails({
        ...receipientDetails,
        [name]: value,
        bankName: "",
      });
      setBanksSelect(banks[value] || []);
    } else if (name === "bankName") {
      let bankSelected = banks[receipientDetails.country]?.find(
        (data) => data.name === value
      );

      setReceipientDetails({
        ...receipientDetails,
        [name]: value,
        bankCode: bankSelected.code,
      });
    } else {
      setReceipientDetails({
        ...receipientDetails,
        [name]: value,
      });
    }

    setShowType(true);
  }

  function onChangeBank(e) {
    setPaymentMethod(e.target.value);
  }

  function handleOnChangePhoneInput(value) {
    setReceipientDetails({ ...receipientDetails, phoneNumber: value });
  }

  function handleFieldChange(value) {
    if (receipientDetails.country === "NG") {
      let params = {
        countryCode: receipientDetails.country,
        bankCode: bankCode,
        accountNumber: value.target.value,
      };

      setLoading(true);
      return makeAPICall({
        path: "settings/banks/accounts",
        params,
        method: "GET",
      })
        .then((res) => {
          setReceipientDetails({
            ...receipientDetails,
            bankAccountName: res?.data?.account_name,
          });
          message.success("Account name fetched successfully", 5);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setBanksSelect(banks[receipientDetails.country] || []);
  }, [banks, receipientDetails.country]);

  useEffect(() => {
    let bankInfo = banksSelect.filter((data) => data.name === bankName);
    if (bankInfo.length > 0) setBankCode(bankInfo[0].code);
  }, [bankName, banksSelect]);

  function allowNumbersOnly(event) {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      event.keyCode === 190 ||
      event.keyCode === 110
    ) {
      return event;
    } else {
      return event.preventDefault();
    }
  }

  function onInputValueChanged(event) {
    setReceipientDetails({
      ...receipientDetails,
      [event.target.name]: event.target.value,
    });
  }

  const selectedCountry = countries.filter((country) => {
    return country.country === receipientDetails.country;
  });

  // console.log(countries, selectedCountry, 'test')

  let showBankForm = selectedCountry[0]?.paymentTypes.filter(
    (val) => val === "bank" || val === "momo"
  );

  return (
    <>
      <Form className={`playground-pay ${styles.formWrapper}`}>
        <p>Choose receiver's country</p>
        <Select
          showSearch
          placeholder="Select beneficiary country"
          optionFilterProp="children"
          onChange={(value) => onSelectChange(value, "country")}
          className="form__select mt-1"
          value={receipientDetails.country}
          name="country"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {countries.map((data) => (
            <Option key={data.country} value={data.country}>
              {data.name}
            </Option>
          ))}
        </Select>
        <br />
        <br />
        <p>Receiver's phone number</p>
        <ReactPhoneInput
          inputProps={{
            name: "phoneNumber",
            required: true,
            className: "formInput",
          }}
          country={receipientDetails.country.toLowerCase()}
          countryCodeEditable={false}
          // enableSearch={true}
          value={receipientDetails.phoneNumber}
          onChange={handleOnChangePhoneInput}
          onlyCountries={countryArray}
          disableCountryCode={false}
          placeholder="0800 000 0000"
          autoFormat={false}
          enableClickOutside={true}
        />
        <br />
        {showType && (
          <Radio.Group onChange={onChangeBank} value={paymentMethod}>
            <Space>
              {showBankForm.map((record, index) => (
                <Radio value={record === "bank" ? "bank" : "momo"} key={index}>
                  <img
                    src={record === "bank" ? bank : momo}
                    alt=""
                    style={{
                      marginRight: "10px",
                      width: "12px",
                      marginTop: "-4px",
                    }}
                  />
                  {record}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          // <>
          //   {receipientDetails.country === "NG" ? (
          //     <>
          //       <p>Choose available payment type</p>
          //       <Radio.Group onChange={onChangeBank} value={paymentMethod}>
          //         <Space>
          //           <Radio value="bank">
          //             <img
          //               src={bank}
          //               alt=""
          //               style={{
          //                 marginRight: "10px",
          //                 width: "12px",
          //                 marginTop: "-4px",
          //               }}
          //             />
          //             Bank
          //           </Radio>
          //         </Space>
          //       </Radio.Group>
          //     </>
          //   ) : (
          //     <>
          //       <p>Choose available payment type</p>
          //       <Radio.Group onChange={onChangeBank} value={paymentMethod}>
          //         <Space>
          //           <Radio value="momo">
          //             <img
          //               src={momo}
          //               style={{
          //                 marginRight: "7px",
          //                 width: "11px",
          //                 marginTop: "-4px",
          //               }}
          //               alt=""
          //             />
          //             Momo
          //           </Radio>
          //         </Space>
          //       </Radio.Group>
          //     </>
          //   )}
          // </>
        )}

        <br />
        <br />
        {paymentMethod === "bank" ? (
          <>
            <p>Reciever's bank name</p>
            <Select
              showSearch
              placeholder="Select Bank"
              optionFilterProp="children"
              onChange={(value) => {
                onSelectChange(value, "bankName");
                setBankName(value);
              }}
              className="form__select mt-1"
              value={receipientDetails.bankName}
              name="bankName"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {banksSelect.map((data) => (
                <Option key={data.id} value={data.name}>
                  {data.name}
                </Option>
              ))}
            </Select>
            <br />
            <br />
            <p>Bank account number</p>
            <Input
              placeholder="101124354234"
              value={receipientDetails.bankAccountNumber}
              onChange={(value) => {
                onInputValueChanged(value);
              }}
              onBlur={(value) => {
                onInputValueChanged(value);
                handleFieldChange(value);
              }}
              className="mt-2"
              name="bankAccountNumber"
              maxLength={15}
              onKeyDown={allowNumbersOnly}
            />
            <br />
            <br />
            <div className="flex justify-content-end">
              {loading ? <Spin /> : null}
            </div>
            <p>Bank account name</p>
            <Input
              placeholder="John Doe"
              value={receipientDetails.bankAccountName}
              onChange={onInputValueChanged}
              className="mt-2"
              name="bankAccountName"
              disabled={receipientDetails.country === "NG"}
            />
          </>
        ) : null}

        <br />

        <br />
        <Form.Item className={styles.footer}>
          <Button
            size="large"
            type="default"
            className="grey-btn"
            onClick={goBack}
            style={{ width: "49%" }}
          >
            Go Back
          </Button>
          <Button
            size="large"
            type="primary"
            className="pay-blue-btn"
            onClick={() => handleClick(receipientDetails, paymentMethod)}
            disabled={
              !(
                receipientDetails.phoneNumber &&
                receipientDetails.country &&
                paymentMethod
              )
            }
            style={{ width: "49%", marginLeft: "5px" }}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
      {/* <Form>
            <p>Receiver's phone number</p>
            <ReactPhoneInput
              inputProps={{
                name: "phoneNumber",
                required: true,
                className: "formInput",
              }}
              countryCodeEditable={false}
              enableSearch={true}
              preferredCountries={["ng", "gb", "za"]}
              value={receipientDetails.phoneNumber}
              onChange={handleOnChangePhoneInput}
              disableCountryCode={false}
              placeholder="0800 000 0000"
              autoFormat={false}
              enableClickOutside={true}
            />
            <br />
            <Form.Item>
              <Button
                size="large"
                type="default"
                className="grey-btn"
                onClick={goBack}
                style={{ width: "49%" }}
              >
                Go Back
              </Button>
              <Button
                size="large"
                type="primary"
                className="pay-blue-btn"
                onClick={() => handleClick(receipientDetails)}
                disabled={!(receipientDetails.phoneNumber)}
                style={{ width: "49%", marginLeft: "5px" }}
              >
                Continue
              </Button>
            </Form.Item>
          </Form> */}
    </>
  );
}
