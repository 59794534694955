import React, { useState, useEffect, useRef } from "react";
import { formatMoney } from "../../../utilities/helperFunctions";
import Loader from "../../loaders/loading";
import { Button, Form, Typography, Radio, Space, message, Spin } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import makeAPICall from "../../../utilities/apiUtils";
import * as ctzc from "iso-country-currency";
import * as ctzct from "country-tz-currency";
import VerifyPhoneNumberModal from "../VerifyPhoneNumber";

const { Paragraph } = Typography;

export default function Second({
  fundType,
  amount,
  currency,
  nextOthers,
  nextMomo,
  prev,
}) {
  const [records, setRecords] = useState();
  const [seeRecords, setSeeRecords] = useState(false);
  const [value, setValue] = useState("");
  const [viewAddNumber, setViewAddNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [allNumbers, setAllNumbers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [fetchingPhoneNumber, setFetchingPhoneNumber] = useState(false);
  const [addingPhoneNumber, setAddingPhoneNumber] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [trueState, setTrueState] = useState(false);
  const [otpInput, setOtpInput] = useState();
  const [country, setCountry] = useState(
    currency === "XAF"
      ? "CM"
      : currency === "XOF"
      ? "CI"
      : ctzc.getAllISOByCurrencyOrSymbol("currency", currency).toString()
  );

  const debounceTimeout = useRef(null);

  const [modals, setModals] = useState({
    verifyPhoneModal: false,
  });

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (fundType === "bank") {
      const data = {
        currency: currency,
        amount: amount,
      };

      return makeAPICall({
        path: "transactions/collections/bank",
        payload: data,
        method: "POST",
      })
        .then((res) => {
          const { data } = res;
          setLoader(false);
          setRecords(data);
          setSeeRecords(true);
        })
        .catch((err) => {
          message.error(err.message, 5);
          setLoader(false);
        });
    } else if (fundType === "momo") {
      setLoader(false);
      setSeeRecords(false);
      setFetchingPhoneNumber(true);
      return makeAPICall({
        path: `transactions/collections/momoAccounts?page=1&limit=20&country=${country}`,
        method: "GET",
      })
        .then((res) => {
          const { accounts } = res.data;
          setFetchingPhoneNumber(false);
          setAllNumbers(accounts);
        })
        .catch((err) => {
          setFetchingPhoneNumber(false);
        });
    }
  }, [fundType, currency, amount, country]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (!otpInput) {
      setPhoneDisabled(true);
    } else {
      setPhoneDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpInput]);

  function handleOnChangePhoneInput(value, country) {
    setPhoneNumber(value);
    setCountry(country.countryCode.toUpperCase());
  }

  const handleSearchNumber = (value, country) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      // Trigger the network request after debounce
      if (value) fetchData(value, country);
    }, 1000);
  };

  const fetchData = (value, country) => {
    setLoading(true);
    return makeAPICall({
      path: `transactions/collections/momoAccounts?page=1&limit=20&phone=${value}&country=${country.countryCode.toUpperCase()}`,
      method: "GET",
    })
      .then((res) => {
        const { accounts } = res.data;
        setLoading(false);
        setAllNumbers(accounts);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const addNumber = () => {
    setViewAddNumber(true);
  };

  const showOld = () => {
    setViewAddNumber(false);
  };

  const addNewNumber = () => {
    // console.log(phoneNumber, "phoneNumber");
    setAddingPhoneNumber(true);
    const data = {
      country: country,
      phone: phoneNumber,
    };
    return makeAPICall({
      path: "transactions/collections/momoAccounts",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        message.success("Verification code sent", 5);
        setTrueState(true);
        setAddingPhoneNumber(false);
        setViewAddNumber(false);
        openModal();
      })
      .catch((err) => {
        setAddingPhoneNumber(false);
        message.error(err.message, 5);
      });
  };

  const resendOtp = () => {
    setTrueState(false);
    const data = {
      country: country,
      phone: phoneNumber,
    };
    return makeAPICall({
      path: "transactions/collections/momoAccounts",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setTrueState(true);
        message.success("Verification code has been resent", 5);
      })
      .catch((err) => {
        setTrueState(true);
        message.error(err.message, 5);
      });
  };

  function openModal() {
    setModals({ ...modals, verifyPhoneModal: true });
  }

  const closeModal = () => {
    setModals({ ...modals, verifyPhoneModal: false });
  };

  function handlePhonePinChange(otpInput) {
    setOtpInput(otpInput);
  }

  const makeCall = (e) => {
    e.preventDefault();
    setLoadingVerify(true);
    const code = otpInput.toString();
    const data = {
      code: code,
      phone: phoneNumber,
      country: country,
    };
    return makeAPICall({
      path: "transactions/collections/momoAccounts/verify",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        // console.log(res, "tetststtss for verify number");
        message.success("Phone number verified", 5);
        return makeAPICall({
          path: "transactions/collections/momoAccounts?page=1&limit=10",
          method: "GET",
        })
          .then((res) => {
            const { accounts } = res.data;
            setLoadingVerify(false);
            let countryDialCode = ctzct.getCountryByCode(country).areaCode;
            countryDialCode = countryDialCode.substring(1);
            const matches = accounts.filter((item) =>
              item.phone.includes(countryDialCode)
            );
            setAllNumbers(matches);
            closeModal();
          })
          .catch((err) => {
            setLoadingVerify(false);
          });
      })
      .catch((err) => {
        setLoadingVerify(false);
      });
  };

  // console.log(allNumbers, 'tetsts')

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div>
          {fundType === "bank" && seeRecords ? (
            <div className="banktransfer-others">
              <div>
                <h2 className="text-center">Bank Transfer</h2>
                <p className="text-center">
                  Make a transfer from your local bank or bank app to eversend
                  account details below.
                </p>
                <br />
                <div className="greyed-bank-transfer">
                  <p className="text-center">Amount to be transferred</p>
                  <h2 className="text-center">
                    {currency}&nbsp;{formatMoney(amount)}
                  </h2>
                </div>
                <br />
                <div className="d-flex">
                  <div>
                    <p className="naija-account-number-p">Bank Name</p>
                    <h1 className="naija-account-number-h1">
                      {records?.bankAccount?.bank}
                    </h1>
                  </div>
                  <div className="ms-auto align-self-center">
                    <Paragraph
                      copyable={{ text: records?.bankAccount?.bank }}
                    ></Paragraph>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <p className="naija-account-number-p">Account Name</p>
                    <h1 className="naija-account-number-h1">
                      {records?.bankAccount?.accountName}
                    </h1>
                  </div>
                  <div className="ms-auto align-self-center">
                    <Paragraph
                      copyable={{ text: records?.bankAccount?.accountName }}
                    ></Paragraph>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <p className="naija-account-number-p">Account Number</p>
                    <h1 className="naija-account-number-h1">
                      {records?.bankAccount?.accountNumber}
                    </h1>
                  </div>
                  <div className="ms-auto align-self-center">
                    <Paragraph
                      copyable={{ text: records?.bankAccount?.accountNumber }}
                    ></Paragraph>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <p className="naija-account-number-p">Swift Code</p>
                    <h1 className="naija-account-number-h1">
                      {records?.bankAccount?.swiftCode}
                    </h1>
                  </div>
                  <div className="ms-auto align-self-center">
                    <Paragraph
                      copyable={{ text: records?.bankAccount?.swiftCode }}
                    ></Paragraph>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <p className="naija-account-number-p">Reference ID</p>
                    <h1 className="naija-account-number-h1">
                      {records?.reference}
                    </h1>
                  </div>
                  <div className="ms-auto align-self-center">
                    <Paragraph
                      copyable={{ text: records?.reference }}
                    ></Paragraph>
                  </div>
                </div>

                <br />
              </div>
              <div className="text-center">
                <button
                  className="btn-btn-btn"
                  onClick={() => nextOthers(records?.reference)}
                >
                  I have made the transfer
                </button>
              </div>
              <h3 className="notaBena">
                *Only click this button after you have made the transfer.
              </h3>
            </div>
          ) : null}
        </div>
      )}

      <div>
        {fundType === "momo" ? (
          <div>
            {viewAddNumber ? (
              <div className="banktransfer-others">
                <div>
                  <h2 className="text-center">Add a new MOMO phone number</h2>
                  <br />
                  <p>Receiver's phone number</p>
                  <ReactPhoneInput
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                      className: "formInput",
                    }}
                    country={country.toLowerCase()}
                    countryCodeEditable={false}
                    value={phoneNumber}
                    onChange={handleOnChangePhoneInput}
                    onlyCountries={[country.toLowerCase()]}
                    // disableCountryCode={false}
                    placeholder="0800 000 0000"
                    autoFormat={false}
                    enableClickOutside={true}
                  />

                  <br />
                  <br />

                  <div className="text-center">
                    <button className="newBtnPPlay" onClick={showOld}>
                      Cancel and go back
                    </button>
                  </div>
                  <br />
                </div>
                <div className="text-center">
                  <button
                    className="btn-btn-btn"
                    disabled={!phoneNumber || addingPhoneNumber}
                    onClick={addNewNumber}
                  >
                    {addingPhoneNumber ? "Loading" : "Add Phone number"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="banktransfer-others">
                <div>
                  <h2 className="text-center">MOMO transfer</h2>
                  <p className="text-center">
                    Make a transfer from your MOMO account
                  </p>
                  <br />
                  <div className="greyed-bank-transfer">
                    <p className="text-center">Amount to be transferred</p>
                    <h2 className="text-center">
                      {currency}&nbsp;{formatMoney(amount)}
                    </h2>
                  </div>
                  <br />

                  <h3 className="some-title">
                    Search for a phone number here{" "}
                  </h3>

                  <ReactPhoneInput
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                      className: "formInput",
                    }}
                    country={country.toLowerCase()}
                    countryCodeEditable={false}
                    value={phoneNumber}
                    onChange={handleSearchNumber}
                    onlyCountries={[country.toLowerCase()]}
                    // disableCountryCode={false}
                    placeholder="0800 000 0000"
                    autoFormat={false}
                    enableClickOutside={true}
                    // style={{ width: "80%" }}
                  />
                  {loading ? <Spin tip="Loading fetched numbers..." /> : null}

                  <br />
                  <br />

                  <h3 className="some-title">Select number to top up from</h3>
                  <br />

                  {fetchingPhoneNumber ? (
                    <Spin />
                  ) : (
                    <div>
                      {allNumbers.length === 0 ? (
                        <p>No Number Added Yet</p>
                      ) : (
                        <>
                          <Radio.Group onChange={onChange} value={value}>
                            <Space direction="vertical">
                              {allNumbers.map((number, index) => (
                                <Radio value={number.phone} key={index}>
                                  {number.phone}
                                </Radio>
                              ))}
                            </Space>
                          </Radio.Group>
                        </>
                      )}
                    </div>
                  )}

                  {/* <br/>
                <div className="bank-transfer-modal">
                  <div className="d-flex">
                    <p className="">+233 456 7656 2563</p>
                  </div>
                </div> */}
                  <div className="newBankAccount mb-4" onClick={addNumber}>
                    Add new MOMO number <i className="fa fa-plus"></i>
                  </div>
                </div>
                <Form layout="vertical">
                  <Form.Item>
                    <Button
                      size="large"
                      type="default"
                      className="grey-btn"
                      onClick={prev}
                      style={{ width: "47%" }}
                    >
                      Go Back
                    </Button>
                    <Button
                      size="large"
                      type="default"
                      className="pay-blue-btn"
                      onClick={() => nextMomo(value, country, amount, currency)}
                      disabled={!value}
                      style={{ width: "47%", float: "right" }}
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <VerifyPhoneNumberModal
        show={modals.verifyPhoneModal}
        userNumber={phoneNumber}
        onClose={closeModal}
        phoneDisabled={phoneDisabled}
        // pinFields={pinFields}
        handlePhonePinChange={handlePhonePinChange}
        loading={loadingVerify}
        loadingState={loadingVerify}
        onClick={makeCall}
        trueState={trueState}
        resendOtp={resendOtp}
      />
    </div>
  );
}
