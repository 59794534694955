import React, { useState } from "react";
import { formatBalance, formatMoney } from "../../../utilities/helperFunctions";
import { useSelector, useDispatch } from "../../../utilities/hooks";
import StyledModal from "../styled-modal";
import { maskCardMiddleNumbers } from "../../../utilities/helperFunctions";
import moment from "moment";
import ClosedEye from "../../../assets/images/Eye_Closed.svg";
import OpenEye from "../../../assets/images/Eye_Open.svg";
import masterCard from "../../../assets/images/master-card.svg";
import visaCard from "../../../assets/images/visa-logo.svg";
import { InputNumber } from "antd";
import logo from "../../../assets/images/v-card-logo.svg";
import { ReactComponent as LineIcon } from "../../../assets/images/connect-line.svg";
import { ReactComponent as FeeIcon } from "../../../assets/images/exchange-card-icon.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success-check-mark.svg";
import { ReactComponent as Rejected } from "../../../assets/images/failed-action.svg";
import makeAPICall from "../../../utilities/apiUtils";
import CardDetails from "../CardDetails";
import {
  fetchWalletsAction,
  fetchCardsAction,
  fetchCardAnalyticsAction,
} from "../../../services/actions";
import Loader from "../../Loader";

export default function FundCard({ show, onClose, cardDetails }) {
  const dispatch = useDispatch();
  const { wallets } = useSelector((state) => state.walletsReducer);
  const { user } = useSelector((state) => state.profileReducer);
  const [showCardSummary, setShowCardSummary] = useState(true);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cardDetailsAll, setCardDetailsAll] = useState(cardDetails);
  const [paymentSuccessful, setPaymentSuccessful] = useState({
    key: 0,
    value: false,
  });
  const [cardDetailsModal, setCardDetailsModal] = useState(false);
  const [newCardDetails, setNewCardDetails] = useState({});

  let USDWallet = wallets.find((wallet) => wallet.currency === "USD");

  let colors = {
    blue: "#5a50fe",
    black: "#040052",
    purple: "#FF3599",
    orange: "#00C279",
    yellow: "#EAA5FF",
  };

  function toggleCardSummaryDisplay() {
    setShowCardSummary(!showCardSummary);
  }

  const onChange = (value) => {
    setAmount(value);
  };

  const handleSubmit = () => {
    const data = {
      cardId: cardDetailsAll.card.id,
      amount: amount,
      currency: "USD",
    };
    setLoading(true);
    return makeAPICall({
      path: "cards/fund",
      method: "POST",
      payload: data,
    })
      .then((res) => {
        // console.log(res);
        cardDetails.card.balance = res.data.newBalance;
        setNewCardDetails(cardDetails);
        setPaymentSuccessful({
          key: 0,
          value: true,
        });
        setLoading(false);
        dispatch(fetchWalletsAction());
        dispatch(fetchCardsAction({}));
        dispatch(fetchCardAnalyticsAction({}));
        return makeAPICall({
          path: `cards/${cardDetailsAll.card.id}`,
          method: "GET",
        })
          .then((res) => {
            setCardDetailsAll(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        setNewCardDetails(cardDetails);
        setLoading(false);
        setPaymentSuccessful({
          key: 1,
          value: true,
        });
      });
  };

  const fundAgain = () => {
    setAmount(0);
    setPaymentSuccessful(false);
  };

  function onOpenModal() {
    setCardDetailsModal(true);
    // onClose();
  }

  function onCloseModal() {
    setCardDetailsModal(false);
    onClose();
  }

  return (
    <div>
      <div>
        <StyledModal show={show} onClose={onClose}>
          <div className="custom-padding">
            <div className="d-flex justify-content-between align-items-center v-card-header">
              <div>
                <h1>Fund card</h1>
              </div>
              {/* <h2>Minimise view</h2> */}
            </div>
            <br />
            <div className="row">
              <div className="col-md-4">
                <div>
                  <div className="d-flex align-items-center v-card-show">
                    <h1 className="me-1">Show</h1>
                    {showCardSummary ? (
                      <img
                        onClick={toggleCardSummaryDisplay}
                        src={ClosedEye}
                        alt="open"
                        width="25px"
                        height="auto"
                      />
                    ) : (
                      <img
                        onClick={toggleCardSummaryDisplay}
                        src={OpenEye}
                        alt="open"
                        width="25px"
                        height="auto"
                      />
                    )}
                  </div>
                </div>
                <div
                  className="virtual-card-design p-4 mt-3"
                  style={{
                    backgroundColor:
                      colors[cardDetailsAll?.card?.color] || "#5a50fe",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <img src={logo} alt="Eversend logo" />
                    <h2>VIRTUAL</h2>
                  </div>
                  <h1 className="mt-3">
                    {!showCardSummary
                      ? cardDetailsAll?.card?.number
                      : maskCardMiddleNumbers(cardDetailsAll?.card?.number)}
                  </h1>
                  <div className="d-flex align-items-center mt-3">
                    <div>
                      <p>Valid thru</p>
                      <h3>
                        <span>{cardDetailsAll?.card?.expiration}</span>
                      </h3>
                    </div>

                    <div className="ms-3">
                      <p>CVV</p>
                      <h3>
                        {!showCardSummary
                          ? cardDetailsAll?.card?.securityCode
                          : "***"}
                      </h3>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <h4>{cardDetailsAll?.card?.name}</h4>
                    <img
                      src={
                        cardDetailsAll?.card?.brand === "Visa"
                          ? visaCard
                          : masterCard
                      }
                      alt="mastercard logo"
                    />
                  </div>
                </div>
                <div className="v-card-header mt-3">
                  <h1 className="mb-2">
                    Card for {cardDetailsAll?.card?.title}
                  </h1>
                  <p className="mt-1">
                    Assigned to: {cardDetailsAll?.card?.name}
                  </p>
                </div>

                <h5 className="card-creator mt-2">
                  Created by {user?.account?.name}&nbsp;
                  <span>
                    {moment(cardDetailsAll?.card?.createdAt).format(
                      "MMM DD YYYY"
                    )}
                  </span>
                </h5>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7 fund-card-btn">
                {paymentSuccessful?.key === 0 &&
                paymentSuccessful?.value === true ? (
                  <div
                    style={{ width: "50%" }}
                    className="mt-5 success-card-fund"
                  >
                    <div className="text-center">
                      <SuccessIcon />
                      <div>
                        <h1 className="mt-4">Payment Successful!</h1>
                        <p className="mt-3">
                          You have successfully funded the selected card for USD
                          &nbsp;
                          {amount}
                        </p>
                        <br />
                        <button
                          className="grey-btn-btn mt-4"
                          style={{ cursor: "pointer" }}
                          onClick={fundAgain}
                        >
                          Fund again
                        </button>
                        <br />
                        <br />
                        <button
                          className="btn-btn-btn"
                          style={{ cursor: "pointer" }}
                          onClick={onOpenModal}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                ) : paymentSuccessful?.key === 1 &&
                  paymentSuccessful?.value === true ? (
                  <div
                    style={{ width: "50%" }}
                    className="mt-5 success-card-fund"
                  >
                    <div className="text-center">
                      <Rejected />
                      <div>
                        <h1 className="mt-4">Payment failed</h1>
                        <p className="mt-3">Your card funding failed</p>
                        <br />
                        <button
                          className="grey-btn-btn mt-4"
                          style={{ cursor: "pointer" }}
                          onClick={fundAgain}
                        >
                          Try again
                        </button>
                        <br />
                        <br />
                        <button
                          className="btn-btn-btn"
                          style={{ cursor: "pointer" }}
                          onClick={onOpenModal}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "50%" }} className="mt-5">
                    <div
                      className="fees-card-area"
                      style={{ border: "1px solid #E9EBED" }}
                    >
                      <div>
                        <p style={{ marginLeft: "0px" }} className="mb-2">
                          Active card balance
                        </p>
                        <h1>
                          USD {formatBalance(cardDetailsAll?.card?.balance)}
                        </h1>
                      </div>
                    </div>
                    <br />
                    <div style={{ position: "relative" }}>
                      <div className="custom-select-card">
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            className="d-flex align-items-center p-2"
                            style={{
                              backgroundColor: "#F8F9FA",
                              borderRadius: "40px",
                            }}
                          >
                            <img
                              src={USDWallet?.icon}
                              alt=""
                              className="rounded-circle"
                              width="24px"
                              height="24px"
                            />
                            <p className="ms-2">USD</p>
                          </div>

                          <p>Card top up worth</p>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <p>Balance: USD {formatMoney(USDWallet?.amount)}</p>
                          <div style={{ textAlign: "right" }}>
                            <InputNumber
                              value={amount}
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                              onChange={onChange}
                              bordered={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="fees-card-area-fund">
                        <LineIcon className="line-now" />
                        <FeeIcon className="fee-now" />
                        <p>USD 1 = USD 1</p>
                      </div>
                    </div>
                    <br />
                    <br />
                    <button
                      className="btn-btn-btn mt-4"
                      style={{ cursor: "pointer" }}
                      disabled={!amount || loading}
                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <span className="d-flex align-items-center justify-content-between">
                          <Loader dark={false} />
                        </span>
                      ) : (
                        "Fund card"
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </StyledModal>
      </div>
      {cardDetailsModal && (
        <CardDetails
          cardDetailsAll={newCardDetails}
          tabIndexNow={1}
          onClose={onCloseModal}
          show={cardDetailsModal}
        />
      )}
    </div>
  );
}
