import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Tooltip, Spin, message, Dropdown, Menu } from "antd";
import _ from "lodash";

import {
  formatBalance,
  formatBalanceCrypto,
  excludedCurrencies,
  addMoneyCountries,
} from "../../utilities/helperFunctions";
import { useSelector, useDispatch } from "../../utilities/hooks";
import { AccessControl } from "accesscontrol";
import bankIcon from "../../assets/images/radio-buttons/bankDropdown.svg";
import mobileMoneyIcon from "../../assets/images/radio-buttons/mobileMoneyDropdown.svg";
import requestIcon from "../../assets/images/radio-buttons/requestMoney.svg";
import regroup from "../../assets/images/regroup.svg";
import deleteNow from "../../assets/images/delete.svg";
import send from "../../assets/images/send.svg";
import add from "../../assets/images/add-icon-new.svg";
import left from "../../assets/images/left_circle.svg";
import right from "../../assets/images/right_circle.svg";
import * as ctzc from "iso-country-currency";

import {
  toggleAddMoneyModalAction,
  deactivateWalletAction,
  toggleSendMoneyModalAction,
} from "../../services/actions";
import TagManager from "react-gtm-module";

const tagManagerArgsAdd = {
  dataLayer: {
    event: "b2b_add_money_btn",
  },
};

const tagManagerArgsSend = {
  dataLayer: {
    event: "b2b_send_money_btn",
  },
};

const WalletItems = ({ wallet, index, showBal, user, loading }) => {
  const dispatch = useDispatch();

  // const { inactiveWalletLoading } = useSelector(state => state.activateDeactiveReducer);
  const { accountSummary } = useSelector((state) => state.analyticsReducer);
  const { roles } = useSelector((state) => state.RoleReducer);

  // const { user: userInfo } = useSelector(state => state.profileReducer);

  const [isLoadingWallet, setIsLoadingWallet] = useState({
    loading: false,
    key: 0,
  });

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }

  function handleOpenModal(item) {
    TagManager.dataLayer(tagManagerArgsAdd);
    dispatch(toggleAddMoneyModalAction(wallet, item));
  }

  function handleOpenPayoutModal() {
    TagManager.dataLayer(tagManagerArgsSend);
    dispatch(toggleSendMoneyModalAction());
  }

  const deactivateWallet = () => {
    setIsLoadingWallet({ loading: true, key: index });

    dispatch(
      deactivateWalletAction({ currency: _.get(wallet, "currency", "") })
    );
  };

  const isMainWallet =
    _.get(wallet, "currency", "") === _.get(accountSummary, "currency", "");
  const isCrypto = _.get(wallet, "isCrypto", false);

  const getFormattedBalance = (amount, isCrypto) => {
    return isCrypto ? formatBalanceCrypto(amount) : formatBalance(amount);
  };

  const userMenu = (item) => hasAvailableOption(item);
  function hasAvailableOption(item) {
    const countryCode = ctzc
      .getAllISOByCurrencyOrSymbol("currency", item)
      .toString();
    const country = addMoneyCountries.find((c) => c.country === countryCode);

    if (country) {
      const availableOptions = country.addMoney;
      return (
        <Menu className="header-dropdown-user-container-action-buttons">
          {availableOptions.map((option, index) => (
            <Menu.Item
              key={index}
              className="d-flex align-items-center mb-3"
              onClick={() => handleOpenModal(option)}
            >
              <img
                src={
                  option === "bank"
                    ? bankIcon
                    : option === "momo"
                    ? mobileMoneyIcon
                    : requestIcon
                }
                alt="bank"
              />
              <p className="ms-3">
                {option === "bank"
                  ? "Bank"
                  : option === "momo"
                  ? "Mobile money"
                  : "Eversend"}
              </p>
            </Menu.Item>
          ))}
        </Menu>
      );
    }

    return false; // Country not found in the array
  }

  return (
    <div className="wallet-card-in-list">
      <div>
        <div>
          <div>
            <Tooltip title="Reorder">
              <span>
                <img src={regroup} alt="Reorder" />
              </span>
            </Tooltip>
          </div>
          {isMainWallet ? (
            <React.Fragment />
          ) : (
            <div>
              <Tooltip title="Deactivate wallet">
                {checkAccess("wallets").granted ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a href="#" onClick={deactivateWallet}>
                    {loading &&
                    isLoadingWallet.loading &&
                    isLoadingWallet.key === index ? (
                      <Spin size="small" />
                    ) : (
                      <React.Fragment>
                        <img src={deleteNow} alt="Deactivate wallet" />
                      </React.Fragment>
                    )}
                  </a>
                ) : null}
              </Tooltip>
            </div>
          )}
          <div>
            <Tooltip title="Send money">
              {checkAccess("payouts").granted ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={handleOpenPayoutModal}>
                  <img src={send} alt="Send money" />
                </a>
              ) : null}
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Fund wallet">
              {checkAccess("collections").granted ? (
                excludedCurrencies.includes(wallet.currency) ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href="#"
                    onClick={() =>
                      message.error("You can not add money to this wallet")
                    }
                  >
                    <img src={add} alt="Add money" />
                  </a>
                ) : (
                  <div>
                    {wallet.isCrypto ? (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a href="#" onClick={() => handleOpenModal("crypto")}>
                        <img src={add} alt="Add money" />
                      </a>
                    ) : (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a href="#">
                        <Dropdown
                          overlay={userMenu(wallet.currency)}
                          trigger={["click"]}
                          placement="bottomRight"
                        >
                          <img src={add} alt="Add money" />
                        </Dropdown>
                      </a>
                    )}
                  </div>
                )
              ) : null}
            </Tooltip>
          </div>
        </div>
        <div>
          <Link
            to={{
              pathname: `/wallets/currency/${_.get(wallet, "currency", "")}`,
            }}
          >
            <div>
              <Avatar src={_.get(wallet, "icon", "")} size={36} />
              <div>
                <h4>
                  {_.get(wallet, "currency", "")}
                  &nbsp;&nbsp;
                  {`${
                    showBal
                      ? getFormattedBalance(
                          _.get(wallet, "amount", 0),
                          isCrypto
                        )
                      : "*********"
                  }`}
                </h4>
                <p>{_.get(wallet, "name", "")}</p>
              </div>
            </div>
          </Link>
        </div>
        <div>
          <h4>Transactions (Last 30 days)</h4>
          <ul>
            <li>
              <div>
                <img src={left} alt="" />
                <span>Collections</span>
              </div>
              <div>
                <p>
                  {_.get(wallet, "currency", "")}
                  &nbsp;
                  {getFormattedBalance(
                    _.get(wallet, "totalCollections", 0),
                    isCrypto
                  )}
                </p>
                <span
                  className={`${
                    _.get(wallet, "percentageChangeInCollections", 0) < 0
                      ? "negative"
                      : "positive"
                  }-percentage-change`}
                >
                  {_.get(wallet, "percentageChangeInCollections", 0)}%
                </span>
              </div>
            </li>
            <li>
              <div>
                <img src={right} alt="" />
                <span>Payouts</span>
              </div>
              <div>
                <p>
                  {_.get(wallet, "currency", "")}
                  &nbsp;
                  {getFormattedBalance(
                    _.get(wallet, "totalPayouts", 0),
                    isCrypto
                  )}
                </p>
                <span
                  className={`${
                    _.get(wallet, "percentageChangeInPayouts", 0) < 0
                      ? "negative"
                      : "positive"
                  }-percentage-change`}
                >
                  {_.get(wallet, "percentageChangeInPayouts", 0)}%
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WalletItems;
